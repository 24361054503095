import React, {Component} from 'react';
import Joi from 'joi-browser';
import Axios from "axios";
import {apiURL} from "../../config";
import moment, {months} from "moment";

const apiEndpoint = apiURL + "/holidays";

class HolidayForm extends Component {
  state = {
    data: {id: 0, holidayName: "", year: moment().year(), month: "", date: ""},
    errors: {},
    maxDate: 31
  };

  schema = {
    id: Joi.number(),
    holidayName: Joi.string().required().label("Nama Hari Libur"),
    year: Joi.number().required().label("Tahun"),
    month: Joi.number().required().label("Bulan"),
    date: Joi.number().required().label("Tanggal"),
  };

  async componentDidMount() {
    try {
      const itemId = this.props.match.params.id;
      if (itemId === "new") return;

      if (itemId) {
        const {data} = await Axios.get(apiEndpoint + "/" + itemId);
        if (data.year === null) {
          data.year = "";
        }
        this.setState({data});
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.props.history.replace("/not-found");
      }
    }
  }

  handleChange = ({target: input}) => {
    const data = {...this.state.data};
    data[input.name] = input.value;
    this.setState({data}, () => {
      if (input.name === "month") {
        const maxDate = moment(new Date(this.state.data.year, this.state.data.month - 1, 1)).endOf("month").date();
        this.setState({maxDate: maxDate});
      }
    });
  };

  handleSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) return;

    await HolidayForm.saveForm(this.state.data);
    this.props.history.push("/holidays");
  };

  validate = () => {
    const options = {abortEarly: false, stripUnknown: true};
    const {error} = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  static saveForm(data) {
    if (data.id) {
      const body = {...data};
      return Axios.put(apiEndpoint + "/" + data.id, body);
    }
    return Axios.post(apiEndpoint, data);
  }

  render() {
    const {data, errors} = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Formulir Hari Libur</h3>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="holidayName">Nama Hari Libur</label>
                <input id="holidayName"
                       name="holidayName"
                       value={data.holidayName}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control" autoFocus/>
                {errors['holidayName'] && <small className="text-danger">{errors['holidayName']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="year">Tahun</label>
                <input id="year"
                       name="year"
                       value={data.year}
                       onChange={this.handleChange}
                       type="number"
                       min={1}
                       className="form-control"/>
                {errors['year'] && <small className="text-danger">{errors['year']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="month">Bulan</label>
                <select id="month"
                        name="month"
                        value={data.month}
                        onChange={this.handleChange}
                        className="form-control">
                  <option value="">-- Pilih Bulan ---</option>
                  {moment.months().map((month, index) =>
                    <option key={index} value={index+1}>{month}</option>
                  )}
                </select>
                {errors['month'] && <small className="text-danger">{errors['month']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="date">Tanggal</label>
                <input id="date"
                       name="date"
                       value={data.date}
                       onChange={this.handleChange}
                       type="number"
                       min={1}
                       max={this.state.maxDate}
                       className="form-control"/>
                {errors['date'] && <small className="text-danger">{errors['date']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 my-4">
              <button type="submit" className="btn btn-primary mr-2">
                <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>Simpan
              </button>

              <button type="reset" className="btn btn-outline-secondary"
                      onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left mr-2" aria-hidden="true"/>Kembali
              </button>
            </div>
          </div>

        </form>
      </React.Fragment>
    );
  }
}

export default HolidayForm;