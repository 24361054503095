import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Axios from "axios";
import {apiURL} from "../../config";
import Pagination from "react-pagination-js";
import swal from "@sweetalert/with-react/src/sweetalert";

const apiEndpoint = apiURL + "/teachers";

class Teachers extends Component {
  state = {
    data: [],
    search: {
      filter: ""
    },
    page: {
      size: 10, number: 0, totalPages: 0, totalElements: 0
    }
  };

  async componentDidMount() {
    await this.populateData();
  }

  async populateData() {
    try {
      const {size, number} = this.state.page;
      const url = `${apiEndpoint}?page=${number}&size=${size}&sort=teacherName,asc`;
      const response = await Axios.get(url);
      const {teachers} = response.data._embedded;
      const {page} = response.data;
      this.setState({data: teachers, page});
    } catch (e) {
    }
  }

  handlePageChange = async (pageNumber) => {
    const {page} = this.state;
    page.number = pageNumber - 1;

    try {
      const {number, size} = this.state.page;
      const url = `${apiEndpoint}?page=${number}&size=${size}&sort=teacherName,asc`;
      const response = await Axios.get(url);
      const {teachers} = response.data._embedded;
      const {page} = response.data;
      page.number = pageNumber - 1;
      this.setState({data: teachers, page});
    } catch (e) {
    }
  };

  handleEdit = async (item) => {
    this.props.history.push("/teachers/" + item.id);
  };

  handleDelete = async (item) => {
    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin untuk menghapus data ini?",
      icon: "warning",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Poof! Data sudah berhasil dihapus!", {icon: "success"});
        await Axios.delete(apiEndpoint + "/" + item.id);
        await this.populateData();
      }
    });
  };

  handleSearch = async (e) => {
    e.preventDefault();

    try {
      const url = apiURL + "/teacher-service/query?page=0&size=10";
      const {filter} = this.state.search;
      const search = {teacherCode: filter, teacherName: filter, nickname: filter};
      const response = await Axios.post(url, search);
      const {teachers} = response.data._embedded;
      const {page} = response.data;
      this.setState({data: teachers, page});
    } catch (e) {
    }
  };

  handleChange = ({target: input}) => {
    const search = {...this.state.search};
    search[input.name] = input.value;
    this.setState({search});
  };

  render() {
    const {totalElements, totalPages, size, number} = this.state.page;
    const {search} = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Daftar Instruktur</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <form onSubmit={this.handleSearch}>
              <div className="form-group">
                <div className="input-group">
                  <input type="text"
                         id="filter"
                         name="filter"
                         value={search.filter}
                         onChange={this.handleChange}
                         className="form-control" placeholder="" autoComplete="off"/>
                  <div className="input-group-append">
                    <button type="submit" className="btn btn-success btn-sm">
                      <i className="fa fa-search mr-2" aria-hidden="true"/>Cari
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="col-md-6">
            <div className="text-right">
              <Link to="/teachers/new" className="btn btn-primary">
                <i className="fa fa-plus" aria-hidden="true"/> Tambah</Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-hover table-bordered">
              <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Kode</th>
                <th>Nama</th>
                <th>Panggilan</th>
                <th>Telpon</th>
                <th>Email</th>
                <th>Aksi</th>
              </tr>
              </thead>
              <tbody>
              {this.state.data.map((item, index) => (
                <tr key={item._links.self.href}>
                  <td className="text-right fit">{(number * 10) + index + 1}</td>
                  <td>{item.teacherCode}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.nickname}</td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td className="fit">
                    <button onClick={() => this.handleEdit(item)}
                            className="btn btn-info btn-sm mr-2">
                      <i className="fa fa-pencil" aria-hidden="true"/>
                    </button>
                    <button onClick={() => this.handleDelete(item)}
                            className="btn btn-danger btn-sm mr-2">
                      <i className="fa fa-times" aria-hidden="true"/>
                    </button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 my-3 text-center">
            <Pagination
              currentPage={number + 1}
              totalSize={totalElements}
              sizePerPage={size}
              totalPages={totalPages}
              showFirstLastPages={true}
              changeCurrentPage={this.handlePageChange}
              theme="bootstrap"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Teachers;
