import React, {Component} from 'react';
import Joi from 'joi-browser';
import Axios from "axios";
import {apiURL} from "../../config";

const apiEndpoint = apiURL + "/users";
const apiEndpointAuthorities = apiURL + "/authorities";

class UserForm extends Component {
  state = {
    data: {id: 0, username: "", fullName: "", password: "", confirmPassword: "", enabled: 1},
    authorities: [],
    auths: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_INSTRUCTOR'],
    errors: {}
  };

  schema = {
    id: Joi.number(),
    username: Joi.string().required().label("Username"),
    fullName: Joi.string().required().label("Nama Lengkap"),
    password: Joi.string().required().label("Password"),
    confirmPassword: Joi.string().valid(Joi.ref("password")).required().options({language: {any: {'allowOnly': 'Harus sama dengan password'}}}),
    enabled: Joi.number().required().label("Diaktikan")
  };

  async componentDidMount() {
    try {
      const itemId = this.props.match.params.id;
      if (itemId === "new") return;

      if (itemId) {
        const {data} = await Axios.get(apiEndpoint + "/" + itemId);
        data['confirmPassword'] = data['password'];
        this.setState({data});

        await Axios.get(apiEndpoint + "/" + itemId + "/authorities")
          .then((response) => {
            const auths = response.data._embedded.authorities;

            const {authorities} = this.state;
            auths.forEach((item) => {
              if (!authorities.includes(item.authority)) {
                authorities.push(item.authority);
              }
            });
            this.setState({authorities});
          });
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.props.history.replace("/not-found");
      }
    }
  }

  handleChange = ({target: input}) => {
    const data = {...this.state.data};

    if (input.name === "authorities") {
      const {authorities} = this.state;
      if (!authorities.includes(input.value)) {
        authorities.push(input.value);
      }
      this.setState({authorities});
    } else {
      data[input.name] = input.value;
      this.setState({data});
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) return;

    try {
      await UserForm.saveForm(this.state.data, this.state.authorities);
      this.props.history.push("/users");
    } catch (e) {
      if (e.response && e.response.status === 409) {
        const {errors} = this.state;
        errors.username = e.response.data.cause.cause.message;
        this.setState({errors});
      }
    }
  };

  validate = () => {
    const options = {abortEarly: false, stripUnknown: true};
    const {error} = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  static saveForm(data, authorities) {
    if (data.id) {
      const body = {...data};
      return Axios.patch(apiEndpoint + "/" + data.id, body)
        .then(() => {
          Axios.get(apiEndpoint + "/" + data.id + "/authorities")
            .then((response) => {
              const {authorities} = response.data._embedded;
              authorities.forEach((item) => {
                Axios.delete(apiEndpointAuthorities + "/" + item.id);
              });
            }).then(() => {
              authorities.forEach(function (item) {
                const authority = {userId: data.id, username: data.username, authority: item};
                Axios.post(apiEndpointAuthorities, authority);
              });
            });
        });
    }

    return Axios.post(apiEndpoint, data)
      .then((response) => {
        authorities.forEach(function (item) {
          const authority = {userId: response.data.id, username: data.username, authority: item};
          Axios.post(apiEndpointAuthorities, authority);
        });
      });
  }

  clearAuthorities = () => {
    this.setState({authorities: []})
  };

  render() {
    const {data, errors, authorities, auths} = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Formulir Pengguna</h3>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input id="username"
                       name="username"
                       value={data.username}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control" autoFocus autoComplete="username"/>
                {errors['username'] && <small className="text-danger">{errors['username']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="fullName">Nama Lengkap</label>
                <input id="fullName"
                       name="fullName"
                       value={data.fullName}
                       onChange={this.handleChange}
                       className="form-control"/>
                {errors['fullName'] && <small className="text-danger">{errors['fullName']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input id="password"
                       name="password"
                       value={data.password}
                       onChange={this.handleChange}
                       type="password"
                       className="form-control" autoComplete="new-password"/>
                {errors['password'] && <small className="text-danger">{errors['password']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="confirmPassword">Konfirmasi Password</label>
                <input id="confirmPassword"
                       name="confirmPassword"
                       value={data.confirmPassword}
                       onChange={this.handleChange}
                       type="password"
                       className="form-control" autoComplete="new-password"/>
                {errors['confirmPassword'] && <small className="text-danger">{errors['confirmPassword']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="enabled">Diaktifkan</label>
                <select id="enabled"
                        name="enabled"
                        value={data.enabled}
                        onChange={this.handleChange}
                        className="form-control">
                  <option value="1">Aktif</option>
                  <option value="0">Tidak Aktif</option>
                </select>
                {errors['enabled'] && <small className="text-danger">{errors['enabled']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="authorities">Otoritas</label>
                <div className="input-group">
                  <select id="authorities"
                          name="authorities"
                          value={authorities}
                          onChange={this.handleChange}
                          className="form-control" multiple={true}>
                    {auths.map((auth, index) => (
                      <option key={index} value={auth}>{auth}</option>
                    ))}
                  </select>
                  <div className="input-group-append">
                    <button type="button" className="btn btn-outline-secondary" onClick={this.clearAuthorities}>
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-primary mr-2">
            <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>Simpan
          </button>

          <button type="reset" className="btn btn-outline-secondary"
                  onClick={() => this.props.history.goBack()}>
            <i className="fa fa-angle-double-left mr-2" aria-hidden="true"/>Kembali
          </button>

        </form>
      </React.Fragment>
    );
  }
}

export default UserForm;
