import React, {Component} from 'react';
import Axios from "axios";
import {apiURL} from "../../config";
import Joi from "joi-browser";
import swal from "@sweetalert/with-react/src/sweetalert";

const apiEndpoint = apiURL + "/teachers";

class TeacherForm extends Component {
  state = {
    data: {
      id: 0,
      picture: "",
      teacherCode: "",
      username: "",
      teacherName: "",
      nickname: "",
      phone: "",
      email: "",
      mainAddress: "",
      secondaryAddress: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
      twitter: ""
    },
    courses: [],
    availableCourses: [],
    selectedCourses: [],
    availabilities: [],
    schedules: {
      scheduleDay: "",
      times: []
    },
    teacherAvailabilities: [],
    errors: {}
  };

  schema = {
    id: Joi.number(),
    teacherCode: Joi.string().required().label("Kode Instruktur"),
    teacherName: Joi.string().required().label("Nama Instruktur"),
    phone: Joi.string().required().label("Telpon"),
    mainAddress: Joi.string().required().label("Alamat Utama")
  };

  async componentDidMount() {
    try {
      const itemId = this.props.match.params.id;

      const response = await Axios.get(apiURL + "/coursePackages");
      this.setState({availableCourses: response.data._embedded.coursePackages});

      if (itemId === "new") {
        return;
      }

      if (itemId) {
        const url = apiEndpoint + "/" + itemId;
        const {data} = await Axios.get(url);
        this.setState({data});

        const href = url + "/coursePackages";
        const response = await Axios.get(href);

        const {coursePackages} = response.data._embedded;
        this.setState({courses: coursePackages});

        // set course id to selectedCourses state
        this.setState({selectedCourses: coursePackages.map(course => course.id)});

        const availabilityResponse = await Axios.get(this.state.data._links.availabilities.href);
        const {teacherAvailabilities} = availabilityResponse.data._embedded;
        this.setState({teacherAvailabilities});

        const scheduleDays = new Set();
        for (let i = 0; i < teacherAvailabilities.length; i++) {
          scheduleDays.add(teacherAvailabilities[i].day);
        }

        const availabilities = [];
        scheduleDays.forEach(function (value) {
          availabilities.push({scheduleDay: value, times: []});
        });

        for (let i = 0; i < teacherAvailabilities.length; i++) {
          for (let j = 0; j < availabilities.length; j++) {
            if (availabilities[j].scheduleDay === teacherAvailabilities[i].day) {
              availabilities[j].times.push(parseInt(teacherAvailabilities[i].time));
              availabilities[j].times.sort((a, b) => a - b);
            }
          }
        }
        this.setState({availabilities: availabilities});
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.props.history.replace("/not-found");
      }
    }
  }

  handleChange = ({target: input}) => {
    const data = {...this.state.data};

    if (input.type === "file") {
      const reader = new FileReader();
      reader.readAsDataURL(input.files[0]);
      reader.onload = (e) => {
        data[input.name] = e.target.result;
        this.setState({data});
      }
    } else {
      data[input.name] = input.value;
      this.setState({data});
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) return;

    await this.saveForm(this.state.data);
    this.props.history.push("/teachers");
  };

  validate = () => {
    const options = {abortEarly: false, stripUnknown: true};
    const {error} = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  async saveForm(data) {
    let newId;

    if (data.id) {
      const body = {...data};
      await Axios.put(apiEndpoint + "/" + data.id, body);
      newId = data.id;
    } else {
      const response = await Axios.post(apiEndpoint, data);
      newId = response.data.id;
    }

    const headers = {
      "Content-Type": "text/uri-list"
    };

    let courseResources = "";
    for (let i = 0; i < this.state.selectedCourses.length; i++) {
      courseResources += apiURL + "/coursePackages/" + this.state.selectedCourses[i] + "\n";
    }
    await Axios.put(apiEndpoint + "/" + newId + "/coursePackages", courseResources, {headers: headers});

    const teacherAvailabilities = this.state.teacherAvailabilities;
    teacherAvailabilities.map(async (availability) => {
      if (availability.id) {
        await Axios.put(apiURL + "/teacherAvailabilities/" + availability.id, availability);
      } else {
        const response = await Axios.post(apiURL + "/teacherAvailabilities", availability);

        const url = apiURL + "/teacherAvailabilities/" + response.data.id + "/teacher";
        const payload = apiURL + "/teachers/" + this.state.data.id;
        await Axios.put(url, payload, {headers: headers});
      }
    });
  };

  handleSelectedCourseChange = e => {
    const {selectedCourses} = this.state;
    if (e.target.checked) {
      selectedCourses.push(+e.target.value);
    } else {
      let index = selectedCourses.indexOf(+e.target.value);
      selectedCourses.splice(index, 1);
    }

    selectedCourses.sort();
    this.setState({selectedCourses: selectedCourses});
  };

  handleCourseAssignment = e => {
    const assignments = [];
    for (let i = 0; i < this.state.selectedCourses.length; i++) {
      for (let j = 0; j < this.state.availableCourses.length; j++) {
        if (this.state.availableCourses[j].id === this.state.selectedCourses[i]) {
          if (!assignments.includes(this.state.availableCourses[j])) {
            assignments.push(this.state.availableCourses[j]);
          }
        }
      }
    }
    this.setState({courses: assignments});
  };

  handleDeleteCourseAssignment = (item) => {
    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin akan menghapus paket kursus instruktur ini?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Poof! Data sudah berhasil dihapus!", {icon: "success"});
        const currentCourses = this.state.courses.filter(course => course.id !== item.id);
        this.setState({courses: currentCourses});

        const newSelectedCourses = this.state.selectedCourses.filter(id => id !== item.id);
        this.setState({selectedCourses: newSelectedCourses});
      }
    });
  };

  handleScheduleFormChange = ({target: input}) => {
    const schedules = {...this.state.schedules};
    if (input.name.endsWith("time")) {
      if (input.checked) {
        schedules.times.push(input.value);
        schedules.times.sort((a, b) => a - b);
      } else {
        let index = schedules.times.indexOf(input.value);
        schedules.times.splice(index, 1);
      }
    } else {
      schedules[input.name] = input.value;
    }
    this.setState({schedules});
  };

  handleScheduleAssignment = e => {
    const availabilities = this.state.availabilities;
    // clone / deep copy
    const schedules = JSON.parse(JSON.stringify(this.state.schedules));

    availabilities.push(schedules);
    this.setState({availabilities});

    const newTeacherAvailabilities = [];
    for (let i = 0; i < availabilities.length; i++) {
      for (let j = 0; j < availabilities[i].times.length; j++) {
        newTeacherAvailabilities.push({
          teacherId: this.state.data.id,
          day: availabilities[i].scheduleDay,
          time: availabilities[i].times[j]
        });
      }
    }

    const {teacherAvailabilities} = this.state;
    for (let i = 0; i < teacherAvailabilities.length; i++) {
      for (let j = 0; j < newTeacherAvailabilities.length; j++) {
        if (newTeacherAvailabilities[j].day === teacherAvailabilities[i].day
          && newTeacherAvailabilities[j].time === teacherAvailabilities[i].time) {
          newTeacherAvailabilities[j].id = teacherAvailabilities[i].id;
        }
      }
    }

    this.setState({teacherAvailabilities: newTeacherAvailabilities});
  };

  handleDeleteScheduleAssignment = (item) => {
    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin akan menghapus jadwal instruktur ini?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Poof! Data sudah berhasil dihapus!", {icon: "success"});
        const willBeDeleted = this.state.teacherAvailabilities.filter(availability => availability.day === item.scheduleDay);

        willBeDeleted.map(async (availability) => {
          const deleteUrl = apiURL + "/teacherAvailabilities/" + availability.id;
          await Axios.delete(deleteUrl);
        });

        const teacherAvailabilities = this.state.teacherAvailabilities.filter(availability => availability.day !== item.scheduleDay);
        this.setState({teacherAvailabilities});

        const scheduleDays = new Set();
        for (let i = 0; i < teacherAvailabilities.length; i++) {
          scheduleDays.add(teacherAvailabilities[i].day);
        }

        const availabilities = [];
        scheduleDays.forEach(function (value) {
          availabilities.push({scheduleDay: value, times: []});
        });

        for (let i = 0; i < teacherAvailabilities.length; i++) {
          for (let j = 0; j < availabilities.length; j++) {
            if (availabilities[j].scheduleDay === teacherAvailabilities[i].day) {
              availabilities[j].times.push(parseInt(teacherAvailabilities[i].time));
              availabilities[j].times.sort((a, b) => a - b);
            }
          }
        }
        this.setState({availabilities: availabilities});
      }
    });
  };

  render() {
    const {data, courses, availableCourses, selectedCourses, availabilities, errors} = this.state;
    const courseIds = courses.map(x => x.id);

    const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    const scheduleHours = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Formulir Instruktur</h3>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <img src={data.picture === null || data.picture === "" ? "/profile.png" : data.picture} alt={data.name}
                     className="img-thumbnail" width="150" height="150"/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <div className="custom-file">
                  <input id="picture"
                         name="picture"
                         value=""
                         onChange={this.handleChange}
                         type="file"
                         className="custom-file-input"/>
                  <label className="custom-file-label" htmlFor="picture">Pilih Foto</label>
                  {errors['picture'] && <small className="text-danger">{errors['picture']}</small>}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="teacherCode">Kode Instruktur</label>
                <input id="teacherCode"
                       name="teacherCode"
                       value={data.teacherCode}
                       onChange={this.handleChange}
                       type="text"
                       disabled={data.id !== 0}
                       className="form-control" autoFocus/>
                {errors['teacherCode'] && <small className="text-danger">{errors['teacherCode']}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="teacherCode">Username</label>
                <input id="username"
                       name="username"
                       value={data.username}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
                {errors['username'] && <small className="text-danger">{errors['username']}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="teacherName">Nama Instruktur</label>
                <input id="teacherName"
                       name="teacherName"
                       value={data.teacherName}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
                {errors['teacherName'] && <small className="text-danger">{errors['teacherName']}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="nickname">Nama Panggilan</label>
                <input id="nickname"
                       name="nickname"
                       value={data.nickname}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="phone">Telpon</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-mobile" aria-hidden="true"/></span>
                  </div>
                  <input id="phone"
                         name="phone"
                         value={data.phone}
                         onChange={this.handleChange}
                         type="text"
                         className="form-control"/>
                </div>
                {errors['phone'] && <small className="text-danger">{errors['phone']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-envelope-o" aria-hidden="true"/></span>
                  </div>
                  <input id="email"
                         name="email"
                         value={data.email}
                         onChange={this.handleChange}
                         type="email"
                         className="form-control"/>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="mainAddress">Alamat Utama</label>
                <textarea id="mainAddress"
                          name="mainAddress"
                          value={data.mainAddress === null ? "" : data.mainAddress}
                          onChange={this.handleChange}
                          className="form-control" rows="3"/>
                {errors['mainAddress'] && <small className="text-danger">{errors['mainAddress']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="secondaryAddress">Alamat Lain</label>
                <textarea id="secondaryAddress"
                          name="secondaryAddress"
                          value={data.secondaryAddress === null ? "" : data.secondaryAddress}
                          onChange={this.handleChange}
                          className="form-control" rows="3"/>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="whatsapp">Whatspp</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-whatsapp" aria-hidden="true"/></span>
                  </div>
                  <input id="whatsapp"
                         name="whatsapp"
                         value={data.whatsapp === null ? "" : data.whatsapp}
                         onChange={this.handleChange}
                         type="text"
                         className="form-control"/>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="facebook">Facebook</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-facebook-official"
                                                          aria-hidden="true"/></span>
                  </div>
                  <input id="facebook"
                         name="facebook"
                         value={data.facebook === null ? "" : data.facebook}
                         onChange={this.handleChange}
                         type="text"
                         className="form-control"/>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="instagram">Instagram</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-instagram" aria-hidden="true"/></span>
                  </div>
                  <input id="instagram"
                         name="instagram"
                         value={data.instagram === null ? "" : data.instagram}
                         onChange={this.handleChange}
                         type="text"
                         className="form-control"/>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="twitter">Twitter</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-twitter" aria-hidden="true"/></span>
                  </div>
                  <input id="twitter"
                         name="twitter"
                         value={data.twitter === null ? "" : data.twitter}
                         onChange={this.handleChange}
                         type="text"
                         className="form-control"/>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="text-right">
                <button type="button" className="btn btn-info btn-sm my-2" data-toggle="modal"
                        data-target="#selectTeacherCoursesModal">
                  <i className="fa fa-plus mr-2" aria-hidden="true"/>Tambah Kursus
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-sm table-hover table-bordered">
                <thead className="thead-light">
                <tr>
                  <th className="text-right fit">#</th>
                  <th>Paket Kursus</th>
                  <th className="fit">Aksi</th>
                </tr>
                </thead>
                <tbody>
                {courses.map((item, index) => (
                  <tr key={item._links.self.href}>
                    <td className="text-right fit">{index + 1}</td>
                    <td>{item.packageCode} - {item.packageName}</td>
                    <td className="text-center fit">
                      <button onClick={() => this.handleDeleteCourseAssignment(item)} type="button"
                              className="btn btn-danger btn-sm">
                        <i className="fa fa-times" aria-hidden="true"/>
                      </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="text-right">
                <button type="button" className="btn btn-info btn-sm my-2" data-toggle="modal"
                        data-target="#addSchedulesModal">
                  <i className="fa fa-plus mr-2" aria-hidden="true"/>Tambah Jadwal
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-sm table-hover table-bordered">
                <thead className="thead-light">
                <tr>
                  <th className="text-right fit">#</th>
                  <th>Hari</th>
                  <th>Jam</th>
                  <th className="fit">Aksi</th>
                </tr>
                </thead>
                <tbody>
                {availabilities.map((item, index) => (
                  <tr key={index}>
                    <td className="text-right fit">{index + 1}</td>
                    <td>{days[item.scheduleDay]}</td>
                    <td>
                      {item.times.map((time, index) =>
                        <button key={index} className="btn btn-info btn-sm mr-1 mb-1 mt-1">{time.toString().padStart(2, '0') + ":00"}</button>
                      )}
                    </td>
                    <td className="text-center fit">
                      <button onClick={() => this.handleDeleteScheduleAssignment(item)}
                              type="button"
                              className="btn btn-danger btn-sm">
                        <i className="fa fa-times" aria-hidden="true"/>
                      </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 my-4">
              <button type="submit" className="btn btn-primary mr-2">
                <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>Simpan
              </button>

              <button type="reset" className="btn btn-outline-secondary"
                      onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left mr-2" aria-hidden="true"/>Kembali
              </button>
            </div>
          </div>
        </form>

        <div className="modal fade" id="selectTeacherCoursesModal" tabIndex="-1" role="dialog"
             aria-labelledby="selectTeacherCoursesModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Daftar Paket Kursus</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Tutup">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-hover table-bordered">
                      <thead className="thead-light">
                      <tr>
                        <th className="fit">
                          <input type="checkbox" name="selectAll"/>
                        </th>
                        <th>Nama Paket Kursus</th>
                      </tr>
                      </thead>
                      <tbody>
                      {availableCourses.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <input id={item.id}
                                   type="checkbox"
                                   name={item.id}
                                   value={item.id}
                                   checked={selectedCourses.includes(item.id) ? "checked" : ""}
                                   onChange={this.handleSelectedCourseChange}
                                   disabled={courseIds.includes(item.id) ? "disabled" : ""}/>
                          </td>
                          <td><label htmlFor={item.id}>{item.packageCode} - {item.packageName}</label></td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Tutup</button>
                <button onClick={this.handleCourseAssignment}
                        type="button" className="btn btn-primary" data-dismiss="modal">Tambah Paket Kursus
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="addSchedulesModal" tabIndex="-1" role="dialog"
             aria-labelledby="addSchedulesModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" role="document">
            <form>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Formulir Jadwal</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Tutup">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="scheduleDay">Hari</label>
                        <select id="scheduleDay"
                                name="scheduleDay"
                                onChange={this.handleScheduleFormChange}
                                className="form-control">
                          <option value="">-- Pilih Hari ---</option>
                          {days.map((day, index) =>
                            <option key={index} value={index}>{day}</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group my-0">
                        <label htmlFor="scheduleTime">Pilih Jam</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {scheduleHours.map((value, index) =>
                      <div key={index} className="col-md-3">
                        <div className="form-group form-check">
                          <input id={value + "time"} name={value + "time"} onChange={this.handleScheduleFormChange}
                                 type="checkbox" className="form-check-input" value={value}/>
                          <label htmlFor={value + "time"} className="form-check-label">{value.toString().padStart(2, '0') + ":00"}</label>
                        </div>
                      </div>
                    )}
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Tutup</button>
                  <button onClick={this.handleScheduleAssignment} type="button" className="btn btn-primary"
                          data-dismiss="modal">Tambah Jadwal
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TeacherForm;
