import React, {Component} from 'react';
import moment from "moment";
import Joi from "joi-browser";
import Axios from "axios";
import {apiURL} from "../../config";

const apiEndpoint = apiURL + "/holiday-service/generate-holidays";

class HolidayInitForm extends Component {
  state = {
    data: {year: moment().year(), includeSunday: false},
    errors: {}
  };

  schema = {
    year: Joi.number().required().label("Tahun"),
  };

  handleChange = ({target: input}) => {
    const data = {...this.state.data};
    if (input.name === "includeSunday") {
      data[input.name] = input.checked;
    } else {
      data[input.name] = input.value;
    }
    this.setState({data});
  };

  handleSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) return;

    await HolidayInitForm.generateHoliday(this.state.data);
    this.props.history.push("/holidays");
  };

  validate = () => {
    const options = {abortEarly: false, stripUnknown: true};
    const {error} = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  static generateHoliday(data) {
    return Axios.post(`${apiEndpoint}?year=${data.year}&includeSunday=${data.includeSunday}`, data);
  }

  render() {
    const {data, errors} = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Buat Kalender Libur</h3>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="year">Tahun</label>
                <input id="year"
                       name="year"
                       value={data.year}
                       onChange={this.handleChange}
                       type="number"
                       min={1}
                       className="form-control"/>
                {errors['year'] && <small className="text-danger">{errors['year']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input"
                         id="includeSunday"
                         name="includeSunday"
                         value={data.includeSunday}
                         checked={data.includeSunday}
                         onChange={this.handleChange}/>
                  <label className="custom-control-label" htmlFor="includeSunday">Minggu sebagai hari libur?</label>
                </div>
                {errors['includeSunday'] && <small className="text-danger">{errors['includeSunday']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 my-4">
              <button type="submit" className="btn btn-primary mr-2">
                <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>Buat Kalender
              </button>

              <button type="reset" className="btn btn-outline-secondary"
                      onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left mr-2" aria-hidden="true"/>Kembali
              </button>
            </div>
          </div>

        </form>
      </React.Fragment>
    );
  }
}

export default HolidayInitForm;