import React from 'react';
import {Route, Switch} from "react-router-dom";
import Navbar from "./components/navbar";
import Students from "./components/students/students";
import StudentForm from "./components/students/studentForm";
import Teachers from "./components/teachers/teachers";
import TeacherForm from "./components/teachers/teacherForm";
import Programs from "./components/programs/programs";
import Studios from "./components/studios/studios";
import StudioForm from "./components/studios/studioForm";
import ProgramForm from "./components/programs/programForm";
import Activities from "./components/activities/activities";
import ActivityFormDay from "./components/activities/activityFormDay";
import NotFound from "./components/notFound/notFound";
import './App.css';
import Courses from "./components/courses/courses";
import CourseForm from "./components/courses/courseForm";
import UserForm from "./components/users/userForm";
import Users from "./components/users/users";
import LoginForm from "./components/loginForm";
import ProtectedRoute from "./components/commons/protectedRoute";
import LogoutForm from "./components/logoutForm";
import StudioCalendar from "./components/activities/studioCalendar";
import Holidays from "./components/holidays/holidays";
import HolidayForm from "./components/holidays/holidayForm";
import HolidayInitForm from "./components/holidays/holidayInitForm";
import IndexForm from "./components/indexForm";
import moment from "moment";
import "react-pagination-js/dist/styles.css";
import ActivityRecapitulationPerInstructor from "./components/reports/activity-recapitulation-per-instructor";
import StudentActivityReport from "./components/reports/studentActivityReport";
import InstructorDailyActivities from "./components/activities/instructorDailyActivities";
import InstructorPayments from "./components/reports/instructorPayments";

function App() {
  return (
    <React.Fragment>
      <Navbar/>

      <main className="container">
        <Switch>
          <Route path="/login" component={LoginForm}/>
          <ProtectedRoute path="/logout" component={LogoutForm}/>
          <ProtectedRoute path="/users/:id" component={UserForm}/>
          <ProtectedRoute path="/users" component={Users}/>
          <ProtectedRoute path="/studios/:id" component={StudioForm}/>
          <ProtectedRoute path="/studios" component={Studios}/>
          <ProtectedRoute path="/students/:id" component={StudentForm}/>
          <ProtectedRoute path="/students" component={Students}/>
          <ProtectedRoute path="/teachers/:id" component={TeacherForm}/>
          <ProtectedRoute path="/teachers" component={Teachers}/>
          <ProtectedRoute path="/courses/:id" component={CourseForm}/>
          <ProtectedRoute path="/courses" component={Courses}/>
          <ProtectedRoute path="/programs/:id" component={ProgramForm}/>
          <ProtectedRoute path="/programs" component={Programs}/>
          <ProtectedRoute path="/activities/:id" component={ActivityFormDay}/>
          <ProtectedRoute path="/activities" component={Activities}/>
          <ProtectedRoute path="/calendar" component={StudioCalendar}/>
          <ProtectedRoute path="/holidays/initiate" component={HolidayInitForm}/>
          <ProtectedRoute path="/holidays/:id" component={HolidayForm}/>
          <ProtectedRoute path="/holidays" component={Holidays}/>
          <ProtectedRoute path="/instructor-activities" component={InstructorDailyActivities} role="ROLE_INSTRUCTOR"/>
          <ProtectedRoute path="/instructor-payments" component={InstructorPayments}/>
          <ProtectedRoute path="/activity-recapitulation-per-instructor" component={ActivityRecapitulationPerInstructor}/>
          <ProtectedRoute path="/student-activity-report" component={StudentActivityReport}/>
          <Route path="/not-found" component={NotFound}/>
          <ProtectedRoute path="/" component={IndexForm}/>
        </Switch>
      </main>

      <div className="border border-light border-bottom-0 my-5"/>

      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                © {moment(new Date()).format("YYYY")} <a href="http://bmtc.school">BMTC</a> - Made in Bali with <i className="fa fa-heart-o"/> by Gana IT Consultants.
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

export default App;
