import React, {Component} from 'react';
import AuthenticationService from '../services/authenticationService';

class LogoutForm extends Component {
  componentDidMount() {
    if (AuthenticationService.isUserLoggedIn()) {
      AuthenticationService.logout();
    }
  }

  render() {
    return (
      <div>

      </div>
    );
  }
}

export default LogoutForm;
