import React, {Component} from 'react';
import {apiURL} from "../../config.json";
import Axios from "axios";
import moment from "moment";
import localeId from "moment/locale/id";
import NumberFormat from "react-number-format";
import AuthenticationService from "../../services/authenticationService";

const apiEndpoint = apiURL + "/activity-service/teacher-payments";

class InstructorPayments extends Component {
  state = {
    data: {
      teacherId: "",
      dateStart: "",
      dateEnd: ""
    },
    teachers: [],
    payments: [],
    totalFee: 0,
    totalPaid: 0,
    totalUnpaid: 0
  };

  async componentDidMount() {
    const url = `${apiURL}/teachers?projection=teacherCodeProjection`;
    const responses = await Axios.get(url);
    const {teachers} = responses.data._embedded;
    this.setState({teachers});

    const data = {...this.state.data};
    teachers.forEach(function (teacher) {
      if (teacher.username === AuthenticationService.getUsername()) {
        data['teacherId'] = teacher.id;
      }
    });
    data['dateStart'] = moment(new Date()).format("YYYY-MM-DD");
    data['dateEnd'] = moment(new Date()).format("YYYY-MM-DD");
    this.setState({data});
  }

  searchInstructorPayment = async(e) => {
    e.preventDefault();
    const search = {"teacherId": this.state.data.teacherId, "dateStart": this.state.data.dateStart, "dateEnd": this.state.data.dateEnd, "status": "2"}
    const paymentSchedules = await Axios.post(apiEndpoint, search);
    this.setState({payments: paymentSchedules.data});

    const {payments} = this.state;
    const totalFee = payments.reduce((totalFee, payment) => totalFee + payment.teacherFee, 0);
    const totalPaid = payments.filter(({paid}) => paid).reduce((totalFee, payment) => totalFee + payment.teacherFee, 0);
    const totalUnpaid = payments.filter(({paid}) => !paid).reduce((totalFee, payment) => totalFee + payment.teacherFee, 0);
    this.setState({totalFee});
    this.setState({totalPaid});
    this.setState({totalUnpaid});
  }

  handleChange = ({target: input}) => {
    const data = {...this.state.data};
    data[input.name] = input.value;
    this.setState({data});
  };

  render() {
    const {data, teachers, payments, totalFee, totalPaid, totalUnpaid} = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Daftar Pembayaran Instruktur</h3>
          </div>
        </div>

        <form>
          <div className="form-row align-items-center">
            <div className="col-md-4 my-1">
              <div className="form-group">
                <label htmlFor="registrationNumber">Instuktur</label>
                <select id="teacherId"
                        name="teacherId"
                        value={data.teacherId}
                        onChange={this.handleChange}
                        disabled={true}
                        className="form-control">
                  <option value="">-- Pilih Satu --</option>
                  {teachers.map((teacher, index) => (
                    <option key={index} value={teacher.id}>{teacher.teacherName}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-3 my-1">
              <div className="form-group">
                <label htmlFor="dateStart">Dari Tanggal</label>
                <input id="dateStart"
                       name="dateStart"
                       value={data.dateStart}
                       onChange={this.handleChange}
                       type="date"
                       className="form-control"/>
              </div>
            </div>
            <div className="col-md-3 my-1">
              <div className="form-group">
                <label htmlFor="dateEnd">Sampai</label>
                <input id="dateEnd"
                       name="dateEnd"
                       value={data.dateEnd}
                       onChange={this.handleChange}
                       type="date"
                       className="form-control"/>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <button type="submit" className="btn btn-success" onClick={this.searchInstructorPayment}>
                <i className="fa fa-search mr-2" aria-hidden="true"/>Cari
              </button>
            </div>
          </div>
        </form>

        <table className="table table-hover table-bordered table-responsive">
          <thead className="thead-light">
          <tr className="text-center">
            <th>#</th>
            <th>Tanggal</th>
            <th>Jam</th>
            <th>Nama Siswa</th>
            <th>No Induk</th>
            <th>No Registrasi</th>
            <th>Nama Kelas</th>
            <th>Fee</th>
            <th>Sudah Dibayar</th>
            <th>Belum Dibayar</th>
            <th>Tanggal Bayar</th>
            <th>No Bayar</th>
          </tr>
          </thead>
          <tbody>
          {payments.map((item, index) => (
            <tr key={index}>
              <td className="fit text-right">{index + 1}</td>
              <td className="fit">{moment(item.activityDate).format("dddd, DD-MMM-YYYY")}</td>
              <td className="fit">{item.startTime}:00 - {item.endTime}:00</td>
              <td className="fit">{item.activity.student.name}</td>
              <td>{item.activity.student.studentCode}</td>
              <td>{item.activity.registrationNumberText}</td>
              <td className="fit">{item.activity.coursePackage.packageName}</td>
              <td className="text-right">
                <NumberFormat value={item.teacherFee} displayType={"text"} prefix={"Rp"} thousandSeparator={"."} decimalSeparator={","} decimalScale={"2"} fixedDecimalScale={true}/>
              </td>
              <td className="text-right">
                {item.paid ?
                  <NumberFormat value={item.teacherFee} displayType={"text"} prefix={"Rp"} thousandSeparator={"."} decimalSeparator={","} decimalScale={"2"} fixedDecimalScale={true} className="text-success"/> :
                  <NumberFormat value={0} displayType={"text"} prefix={"Rp"} thousandSeparator={"."} decimalSeparator={","} decimalScale={"2"} fixedDecimalScale={true} className="text-success"/>}
              </td>
              <td className="text-right">
                {item.paid ?
                  <NumberFormat value={0} displayType={"text"} prefix={"Rp"} thousandSeparator={"."} decimalSeparator={","} decimalScale={"2"} fixedDecimalScale={true} className="text-danger"/> :
                  <NumberFormat value={item.teacherFee} displayType={"text"} prefix={"Rp"} thousandSeparator={"."} decimalSeparator={","} decimalScale={"2"} fixedDecimalScale={true} className="text-danger"/>}
              </td>
              <td className="fit">{item.paymentDate && moment(item.paymentDate).format("DD-MM-YYYY")}</td>
              <td className="fit">{item.paymentNumber}</td>
            </tr>
          ))}
          </tbody>
          <tfoot>
            <tr className="text-center">
              <th colSpan="7">&nbsp;</th>
              <th><NumberFormat value={totalFee} displayType={"text"} prefix={"Rp"} thousandSeparator={"."} decimalSeparator={","} decimalScale={"2"} fixedDecimalScale={true} /></th>
              <th><NumberFormat value={totalPaid} displayType={"text"} prefix={"Rp"} thousandSeparator={"."} decimalSeparator={","} decimalScale={"2"} fixedDecimalScale={true} className="text-success"/></th>
              <th><NumberFormat value={totalUnpaid} displayType={"text"} prefix={"Rp"} thousandSeparator={"."} decimalSeparator={","} decimalScale={"2"} fixedDecimalScale={true} className="text-danger"/></th>
              <th colSpan="2">&nbsp;</th>
            </tr>
          </tfoot>
        </table>

{/*
        <div className="row">
          <div className="col-md-12 my-4">
            <button type="submit" className="btn btn-primary mr-2" onClick={this.printInstructorPayment}>
              <i className="fa fa-print mr-2" aria-hidden="true"/>Cetak
            </button>
          </div>
        </div>
*/}

      </React.Fragment>
    );
  }
}

export default InstructorPayments;
