import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import AuthenticationService from '../services/authenticationService';

class Navbar extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <NavLink className="navbar-brand" to="/">
            <img src="/logo.png" width="30" height="30" alt="Balawan Music Training Centre"/>
          </NavLink>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" to="/calendar">Kalender</NavLink>
              </li>
              <li className="nav-item dropdown">
                  <NavLink className="nav-link" to="/activities">Aktivitas</NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdownMenuLink" role="button"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Data Master
                </NavLink>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <NavLink className="dropdown-item" to="/students">
                    <i className="fa fa-trophy mr-2" aria-hidden="true"/>Siswa
                  </NavLink>
                  <NavLink className="dropdown-item" to="/teachers">
                    <i className="fa fa-user mr-2" aria-hidden="true"/>Instruktur
                  </NavLink>
                  <NavLink className="dropdown-item" to="/courses">
                    <i className="fa fa-microphone mr-2" aria-hidden="true"/>Kursus
                  </NavLink>
                  <NavLink className="dropdown-item" to="/programs">
                    <i className="fa fa-music mr-2" aria-hidden="true"/>Program
                  </NavLink>
                  <NavLink className="dropdown-item" to="/studios">
                    <i className="fa fa-cube mr-2" aria-hidden="true"/>Studio
                  </NavLink>
                </div>
              </li>

              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdownMenuLink"
                         role="button" data-toggle="dropdown" aria-haspopup="true"
                         aria-expanded="false">Laporan
                </NavLink>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <NavLink className="dropdown-item" to="/student-activity-report">
                    <i className="fa fa-file-text-o mr-2" aria-hidden="true"/>Aktivitas Siswa
                  </NavLink>
                </div>
              </li>

              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdownMenuLink"
                         role="button" data-toggle="dropdown" aria-haspopup="true"
                         aria-expanded="false">Instuktur
                </NavLink>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <NavLink className="dropdown-item" to="/instructor-activities">
                    <i className="fa fa-star-half-o mr-2" aria-hidden="true"/>Aktivitas Instruktur
                  </NavLink>
                  <NavLink className="dropdown-item" to="/instructor-payments">
                    <i className="fa fa-file-text-o mr-2" aria-hidden="true"/>Pembayaran Instruktur
                  </NavLink>
                </div>
              </li>

              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdownMenuLink"
                         role="button" data-toggle="dropdown" aria-haspopup="true"
                         aria-expanded="false">Administrasi
                </NavLink>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <NavLink className="dropdown-item" to="/users">
                    <i className="fa fa-user-secret mr-2" aria-hidden="true"/>Pengguna
                  </NavLink>
                  <NavLink className="dropdown-item" to="/holidays">
                    <i className="fa fa-calendar mr-2" aria-hidden="true"/>Hari Libur
                  </NavLink>
                </div>
              </li>
            </ul>
          </div>

          <div className="navbar-nav">
            <div className="nav-item">
              <NavLink className="nav-link" to="/logout" onClick={AuthenticationService.logout}>Logout</NavLink>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
