import React, {Component} from 'react';
import AuthenticationService from '../services/authenticationService';
import { Lines } from 'react-preloaders';

class LoginForm extends Component {
  state = {
    loading: true,
    data: {
      username: "",
      password: "",
    }
  };

  componentDidMount() {
    this.setState({loading: false});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {username, password} = this.state.data;
    AuthenticationService.login(username, password).then(() => {
      AuthenticationService.registerSuccessfulLogin(username, password);
      this.props.history.push("/calendar");
    }).catch(() => {

    });
  };

  handleChange = ({target: input}) => {
    const data = {...this.state.data};
    data[input.name] = input.value;
    this.setState({data});
  };

  render() {
    const {data, loading} = this.state;

    return (
      <React.Fragment>
        <Lines customLoading={loading} time={1000}/>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Login</h3>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input id="username"
                       onChange={this.handleChange}
                       name="username"
                       value={data.username}
                       type="text"
                       className="form-control"
                       autoComplete="username" autoFocus/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input id="password"
                       onChange={this.handleChange}
                       name="password"
                       value={data.password}
                       type="password"
                       className="form-control"
                       autoComplete="current-password"/>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-primary mr-2">
            <i className="fa fa-sign-in mr-2" aria-hidden="true"/>Login
          </button>

        </form>
      </React.Fragment>
    );
  }
}

export default LoginForm;
