import React, {Component} from 'react';
import Joi from 'joi-browser';
import Axios from "axios";
import {apiURL} from "../../config";

const apiEndpoint = apiURL + "/programs";

class ProgramForm extends Component {
  state = {
    data: {id: 0, programCode: "", programName: ""},
    errors: {}
  };

  schema = {
    id: Joi.number(),
    programCode: Joi.string().required().label("Kode Program"),
    programName: Joi.string().required().label("Nama Program")
  };

  async componentDidMount() {
    try {
      const itemId = this.props.match.params.id;
      if (itemId === "new") return;

      if (itemId) {
        const {data} = await Axios.get(apiEndpoint + "/" + itemId);
        this.setState({data});
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.props.history.replace("/not-found");
      }
    }
  }

  handleChange = ({target: input}) => {
    const data = {...this.state.data};
    data[input.name] = input.value;
    this.setState({data});
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) return;

    await ProgramForm.saveForm(this.state.data);
    this.props.history.push("/programs");
  };

  validate = () => {
    const options = {abortEarly: false, stripUnknown: true};
    const {error} = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  static saveForm(data) {
    if (data.id) {
      const body = {...data};
      return Axios.put(apiEndpoint + "/" + data.id, body);
    }

    return Axios.post(apiEndpoint, data);
  }

  render() {
    const {data, errors} = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Formulir Program</h3>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="programCode">Kode Program</label>
                <input id="programCode"
                       name="programCode"
                       value={data.programCode}
                       onChange={this.handleChange}
                       type="text"
                       disabled={data.id !== 0}
                       className="form-control" autoFocus/>
                {errors['programCode'] && <small className="text-danger">{errors['programCode']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="programName">Nama Program</label>
                <input id="programName"
                       name="programName"
                       value={data.programName}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
                {errors['programName'] && <small className="text-danger">{errors['programName']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 my-4">
              <button type="submit" className="btn btn-primary mr-2">
                <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>Simpan
              </button>

              <button type="reset" className="btn btn-outline-secondary"
                      onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left mr-2" aria-hidden="true"/>Kembali
              </button>
            </div>
          </div>

        </form>
      </React.Fragment>
    );
  }
}

export default ProgramForm;
