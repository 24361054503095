import React, {Component} from 'react';
import Axios from "axios";
import {apiURL} from "../../config";
import Joi from "joi-browser";

const apiEndpoint = apiURL + "/coursePackages";

class CourseForm extends Component {
  state = {
    data: {
      id: 0,
      packageCode: "",
      packageName: "",
      capacity: "",
      numberOfSessions: "",
      programId: "",
      price: "",
      pricePerSession: "",
      teacherFee: ""
    },
    programs: [],
    errors: {}
  };

  schema = {
    id: Joi.number(),
    packageCode: Joi.string().required().label("Kode Paket Kursus"),
    packageName: Joi.string().required().label("Nama Paket Kursus"),
    programId: Joi.number().required().label("Program"),
    capacity: Joi.number().required().label("Kapasitas"),
    numberOfSessions: Joi.number().required().label("Jumlah Pertemuan"),
    price: Joi.number().required().label("Harga"),
    pricePerSession: Joi.number().required().label("Harga Per Sesi"),
    teacherFee: Joi.number().required().label("Biaya Instruktur")
  };

  async componentDidMount() {
    try {
      const response = await Axios.get(apiURL + "/programs");
      this.setState({programs: response.data._embedded.programs});

      const itemId = this.props.match.params.id;
      if (itemId === "new") return;

      if (itemId) {
        let url = apiEndpoint + "/" + itemId;
        const {data} = await Axios.get(url);
        // get course program id
        const courseProgram = await Axios.get(url + "/program");
        data.programId = courseProgram.data.id;
        this.setState({data});
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.props.history.replace("/not-found");
      }
    }
  };

  handleChange = ({target: input}) => {
    const data = {...this.state.data};
    if (input.name !== "program") {
      data[input.name] = input.value;
    } else {
      data.programId = input.value;
    }
    this.setState({data});
  };

  handleSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) return;

    await this.saveForm(this.state.data);
    this.props.history.push("/courses");
  };

  validate = () => {
    const options = {abortEarly: false, stripUnknown: true};
    const {error} = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  async saveForm(data) {
    let newId;
    if (data.id) {
      const body = {...data};
      await Axios.put(apiEndpoint + "/" + data.id, body);
      newId = data.id;
    } else {
      const response = await Axios.post(apiEndpoint, data);
      newId = response.data.id;
    }

    const headers = {
      "Content-Type": "text/uri-list"
    };

    let payload = apiURL + "/programs/" + this.state.data.programId;
    await Axios.put(apiEndpoint + "/" + newId + "/program", payload, {headers: headers});
  };

  render() {
    const {data, programs, errors} = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Formulir Paket Kursus</h3>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="packageCode">Kode Paket Kursus</label>
                <input id="packageCode"
                       name="packageCode"
                       value={data.packageCode}
                       onChange={this.handleChange}
                       type="text"
                       disabled={data.id !== 0}
                       className="form-control" autoFocus/>
                {errors['packageCode'] && <small className="text-danger">{errors['packageCode']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="packageName">Nama Paket Kursus</label>
                <input id="packageName"
                       name="packageName"
                       value={data.packageName}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
                {errors['packageName'] && <small className="text-danger">{errors['packageName']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="programId">Program</label>
                <select id="programId"
                        name="programId"
                        value={data.programId}
                        onChange={this.handleChange}
                        className="form-control custom-select">
                  <option value="">-- Pilih Satu --</option>
                  {programs.map((program, index) => (
                    <option key={index} value={program.id}>{program.programCode} - {program.programName}</option>
                  ))}
                </select>
                {errors['programId'] && <small className="text-danger">{errors['programId']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="capacity">Kapasitas</label>
                <input id="capacity"
                       name="capacity"
                       value={data.capacity}
                       onChange={this.handleChange}
                       type="number"
                       className="form-control text-right"/>
                {errors['capacity'] && <small className="text-danger">{errors['capacity']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="numberOfSessions">Jumlah Pertemuan</label>
                <input id="numberOfSessions"
                       name="numberOfSessions"
                       value={data.numberOfSessions}
                       onChange={this.handleChange}
                       type="number"
                       className="form-control text-right"/>
                {errors['numberOfSessions'] && <small className="text-danger">{errors['numberOfSessions']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="price">Harga</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Rp</span>
                  </div>
                  <input id="price"
                         name="price"
                         value={data.price}
                         onChange={this.handleChange}
                         type="number"
                         className="form-control text-right"/>
                </div>
                {errors['price'] && <small className="text-danger">{errors['price']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="pricePerSession">Harga Per Sesi</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Rp</span>
                  </div>
                  <input id="pricePerSession"
                         name="pricePerSession"
                         value={data.pricePerSession}
                         onChange={this.handleChange}
                         type="number"
                         className="form-control text-right"/>
                </div>
                {errors['pricePerSession'] && <small className="text-danger">{errors['pricePerSession']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="teacherFee">Biaya Instruktur</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Rp</span>
                  </div>
                  <input id="teacherFee"
                         name="teacherFee"
                         value={data.teacherFee}
                         onChange={this.handleChange}
                         type="number"
                         className="form-control text-right"/>
                </div>
                {errors['teacherFee'] && <small className="text-danger">{errors['teacherFee']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="moreInfo">Keterangan</label>
                <textarea id="moreInfo"
                          name="moreInfo"
                          value={data.moreInfo === null ? "" : data.moreInfo}
                          onChange={this.handleChange}
                          className="form-control" rows="3"/>
                {errors['moreInfo'] && <small className="text-danger">{errors['moreInfo']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 my-4">
              <button type="submit" className="btn btn-primary mr-2">
                <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>Simpan
              </button>

              <button type="reset" className="btn btn-outline-secondary"
                      onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left mr-2" aria-hidden="true"/>Kembali
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default CourseForm;
