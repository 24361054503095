import React, {Component} from 'react';
import Joi from 'joi-browser';
import Axios from "axios";
import {apiURL} from "../../config";

const apiEndpoint = apiURL + "/rooms";

class StudioForm extends Component {
  state = {
    data: {id: 0, roomCode: "", roomName: "", moreInfo: ""},
    errors: {}
  };

  schema = {
    id: Joi.number(),
    roomCode: Joi.string().required().label("Kode Studio"),
    roomName: Joi.string().required().label("Nama Studio"),
  };

  async componentDidMount() {
    try {
      const itemId = this.props.match.params.id;
      if (itemId === "new") return;

      if (itemId) {
        const {data} = await Axios.get(apiEndpoint + "/" + itemId);
        this.setState({data});
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.props.history.replace("/not-found");
      }
    }
  }

  handleChange = ({target: input}) => {
    const data = {...this.state.data};
    data[input.name] = input.value;
    this.setState({data});
  };

  handleSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) return;

    await StudioForm.saveForm(this.state.data);
    this.props.history.push("/studios");
  };

  validate = () => {
    const options = {abortEarly: false, stripUnknown: true};
    const {error} = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  static saveForm(data) {
    if (data.id) {
      const body = {...data};
      return Axios.put(apiEndpoint + "/" + data.id, body);
    }
    return Axios.post(apiEndpoint, data);
  }

  render() {
    const {data, errors} = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Formulir Studio</h3>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="roomCode">Kode Studio</label>
                <input id="roomCode"
                       name="roomCode"
                       value={data.roomCode}
                       onChange={this.handleChange}
                       type="text"
                       disabled={data.id !== 0}
                       className="form-control" autoFocus/>
                {errors['roomCode'] && <small className="text-danger">{errors['roomCode']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="roomName">Nama Studio</label>
                <input id="roomName"
                       name="roomName"
                       value={data.roomName}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
                {errors['roomName'] && <small className="text-danger">{errors['roomName']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="moreInfo">Keterangan</label>
                <textarea id="moreInfo"
                          name="moreInfo"
                          value={data.moreInfo === null ? "" : data.moreInfo}
                          onChange={this.handleChange}
                          className="form-control" rows="3"/>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 my-4">
              <button type="submit" className="btn btn-primary mr-2">
                <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>Simpan
              </button>

              <button type="reset" className="btn btn-outline-secondary"
                      onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left mr-2" aria-hidden="true"/>Kembali
              </button>
            </div>
          </div>

        </form>
      </React.Fragment>
    );
  }
}

export default StudioForm;
