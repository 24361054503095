import Axios from "axios";
import {baseApiURL} from "../config";

const apiEndpoint = baseApiURL + "authentication-service/authenticate";
export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';

class AuthenticationService {

  async login(username, password) {

    await Axios.get(apiEndpoint, {
      headers: {
        // "Access-Control-Allow-Origin": "*",
        authorization: this.createBasicAuthToken(username, password)
      }
    });
  }

  registerSuccessfulLogin(username, password) {
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
    this.setupAxiosInterceptors(this.createBasicAuthToken(username, password))
  }

  createBasicAuthToken(username, password) {
    return 'Basic ' + window.btoa(username + ":" + password)
  }

  isUserLoggedIn() {
    return sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME) !== null;
  }

  getUsername() {
    return sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
  }

  setupAxiosInterceptors(token) {
    Axios.interceptors.request.use((config) => {
        if (this.isUserLoggedIn()) {
          config.headers.authorization = token;
        }
        return config;
      }
    )
  }

  logout() {
    sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    window.location = "/";
  }
}

export default new AuthenticationService();
