import React, {Component} from 'react';
import Axios from "axios";
import {apiURL} from "../../config.json";
import moment from "moment";
import AuthenticationService from "../../services/authenticationService";

const apiEndpoint = apiURL + "/activity-service/instructor-daily-activities";

class InstructorDailyActivities extends Component {
  state = {
    data: {
      activityDate: moment(new Date()).format("YYYY-MM-DD")
    },
    activities: [],
    courseActivity: {
      id: 0,
      status: "",
      moreInfo: "",
      instructorInfo: ""
    },
    editedCourseActivityId: "",
  };

  async componentDidMount() {
    await this.populateData();
  }

  handleSearch = async (e) => {
    e.preventDefault();
    await this.populateData();
  };

  async populateData() {
    const username = AuthenticationService.getUsername();
    const activityDate = moment(this.state.data.activityDate).format("YYYY-MM-DD");
    let url = apiEndpoint + "?username=" + username + "&activityDate=" + activityDate;

    const response = await Axios.get(url);
    const activities = response.data;
    activities.forEach(function (item) {
      item.startTime = item.startTime + ":00";
      item.endTime = item.endTime + ":00";
    });
    this.setState({activities});
  }

  handleChange = ({currentTarget: input}) => {
    const data = {...this.state.data};
    data[input.name] = input.value;

    this.setState({data});
  }

  handleCourseActivityEdit = (item) => {
    const editedCourseActivityId = item.id;
    this.setState({editedCourseActivityId: editedCourseActivityId}, async () => {
      const response = await Axios.get(apiURL + "/activitySchedules/" + editedCourseActivityId);
      const {data} = response;

      const courseActivity = {...this.state.courseActivity};
      courseActivity.id = data.id;
      courseActivity.status = data.status;
      courseActivity.moreInfo = data.moreInfo === null ? "" : data.moreInfo;
      courseActivity.instructorInfo = data.instructorInfo === null ? "" : data.instructorInfo;
      this.setState({courseActivity});
    });
  };

  handleAddCourseActivity = async (e) => {
    await Axios.post(apiURL + "/activity-service/schedule-info/" + this.state.editedCourseActivityId,
      {"moreInfo": this.state.courseActivity.moreInfo, "instructorInfo": this.state.courseActivity.instructorInfo});

    const courseActivity = {...this.state.courseActivity};
    courseActivity.moreInfo = "";
    courseActivity.instructorInfo = "";
    this.setState({courseActivity});
  };

  handleCourseActivityFormChange = ({target: input}) => {
    const courseActivity = {...this.state.courseActivity};
    courseActivity[input.name] = input.value;
    this.setState({courseActivity});
  };

  render() {
    const {activities, courseActivity} = this.state;
    const {activityDate} = this.state.data;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Daftar Aktivitas Instruktur</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 my-4">
            <form onSubmit={this.handleSearch}>
              <div className="form-group">
                <div className="input-group">
                  <input type="date" id="activityDate" name="activityDate" className="form-control"
                         value={moment(activityDate).format("YYYY-MM-DD")}
                         onChange={this.handleChange}/>
                  <div className="input-group-append">
                    <button type="submit" className="btn btn-success btn-sm">
                      <i className="fa fa-search" aria-hidden="true"/> Cari
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-hover table-bordered table-responsive">
              <thead className="thead-light">
              <tr>
                <th className="fit">#</th>
                <th className="text-center">Aksi</th>
                <th className="fit">Tanggal</th>
                <th className="fit">Jam</th>
                <th className="fit">Status</th>
                <th className="fit">Siswa</th>
                <th className="fit">Studio</th>
                <th className="text-center text-primary" colSpan="2">Mulai</th>
                <th className="text-center text-danger" colSpan="2">Selesai</th>
              </tr>
              </thead>
              <tbody>
              {activities.map((item, index) => (
                <tr key={index}>
                  <td className="text-right fit">{index + 1}</td>
                  <td className="text-left fit">
                    <button type="button" className="btn btn-info btn-sm mr-1" data-toggle="modal"
                            data-target="#courseActivityModal" onClick={() => this.handleCourseActivityEdit(item)}>
                      <i className="fa fa-book" aria-hidden="true" data-toggle="tooltip" title="Info"/>
                    </button>
                  </td>
                  <td className="text-center fit">{moment(item.activityDate).format("ddd, DD-MMM-YYYY")}</td>
                  <td className="text-center fit">{item.startTime}</td>
                  <td className="text-primary text-center fit">
                    {item.status === "1" ? "BERLANGSUNG" : item.status === "2" ? "SELESAI" : ""}
                  </td>
                  <td className="fit">
                    {item.student.name}
                  </td>
                  <td className="fit">
                    {item.room.roomName}
                  </td>
                  <td
                    className="text-primary text-center fit">{item.actualStart !== null && item.actualStart !== "" ? moment(item.actualStart).format("HH:mm") : ""}</td>
                  <td className="text-primary fit">{item.startBy}</td>
                  <td
                    className="text-danger text-center fit">{item.actualFinish !== null && item.actualFinish !== "" ? moment(item.actualFinish).format("HH:mm") : ""}</td>
                  <td className="text-danger fit">{item.finishBy}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="modal fade" id="courseActivityModal" tabIndex="-1" role="dialog"
             aria-labelledby="courseActivityModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" role="document">
            <form>

              <div className="modal-content modal-lg">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Catatan / Remarks</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="moreInfo">Materi Pelajaran</label>
                        <textarea id="moreInfo"
                                  name="moreInfo"
                                  value={courseActivity.moreInfo}
                                  onChange={this.handleCourseActivityFormChange}
                                  className="form-control" rows="5"/>
                      </div>
                    </div>
                  </div>
                  {(courseActivity.status === "1" || courseActivity.status === "2") &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="instructorInfo">Komentar Instruktur</label>
                        <textarea id="instructorInfo"
                                  name="instructorInfo"
                                  value={courseActivity.instructorInfo}
                                  onChange={this.handleCourseActivityFormChange}
                                  className="form-control" rows="5"/>
                      </div>
                    </div>
                  </div>
                  }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">
                    <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                  </button>
                  <button onClick={this.handleAddCourseActivity} type="button" className="btn btn-primary"
                          data-dismiss="modal">
                    <i className="fa fa-save mr-2" aria-hidden="true"/>Simpan
                  </button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default InstructorDailyActivities;
