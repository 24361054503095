import React, {Component} from 'react';

class ActivityRecapitulationPerInstructor extends Component {

  handlePrintReport = () => {
    function printElement(elem) {
      let domClone = elem.cloneNode(true);
      let printSection = document.getElementById("printSection");

      if (!printSection) {
        printSection = document.createElement("div");
        printSection.id = "printSection";
        document.body.appendChild(printSection);
      } else {
        printSection.innerHTML = "";
      }
      printSection.appendChild(domClone);
    }

    printElement(document.getElementById("reportContent"));
    window.print();
  };

  render() {
    return (
      <React.Fragment>

        <div id="reportContent" className="container my-5">
          <div className="row">
            <div className="col-md-2">
              <img src={"/logo.jpg"} height="110" alt="Balawan Music Training Centre"/>
            </div>
            <div className="col-md-10">
              <h4>BMTC</h4>
              <h6>Jalan Suli No. 115</h6>
              <h6>Denpasar 80223 - Bali - Indonesia</h6>
              <h6>Phone: +62-361-225519, Website: http://balawanbmtc.com</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <hr className="border-dark border-bottom"/>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <h4 className="text-uppercase font-weight-bold">Laporan Rekap Aktivitas Per Instruktur</h4>
              <h6 className="text-uppercase font-weight-bold">Periode 01/Jan/2020 - 31/Jan/2020</h6>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <table className="table table-sm table-bordered">
                <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th>Nama Instruktur</th>
                  <th>Fee</th>
                  <th>Terbayar</th>
                  <th>Sisa</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>1</td>
                  <td>Joseph Neta Manurung</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Kadek Allan Dwi Amica</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Tangkas Made Priyanka</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Zidni Ilma Alal Falah</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td></td>
                  <td className="text-right font-weight-bold">Total :</td>
                  <td className="text-right font-weight-bold"></td>
                  <td className="text-right font-weight-bold"></td>
                  <td className="text-right font-weight-bold"></td>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-md-12">
            <button type="button" className="btn btn-primary mr-2" onClick={this.handlePrintReport}>
              <i className="fa fa-print mr-2"/>Cetak
            </button>
            <button type="button" className="btn btn-outline-secondary" onClick={() => this.props.history.goBack()}>
              <i className="fa fa-angle-double-left mr-2"/>Kembali
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ActivityRecapitulationPerInstructor;