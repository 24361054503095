import React, {Component} from 'react';
import Axios from "axios";
import {apiURL} from "../../config";
import Joi from "joi-browser";
import bsCustomFileInput from 'bs-custom-file-input';

const apiEndpoint = apiURL + "/students";

class StudentForm extends Component {
  state = {
    data: {
      id: 0,
      picture: "",
      studentCode: "",
      registrationDate: "",
      termNumber: "",
      sequenceNumber: "",
      name: "",
      nickname: "",
      birthPlace: "",
      birthDate: "",
      phone: "",
      email: "",
      address: "",
      occupation: "",
      parentName: "",
      parentPhone: "",
      moreInfo: ""
    },
    errors: {}
  };

  schema = {
    id: Joi.number(),
    studentCode: Joi.string().required().label("No. Induk"),
    registrationDate: Joi.date().required().label("Tanggal Registrasi"),
    name: Joi.string().required().label("Nama Siswa"),
    birthPlace: Joi.string().required().label("Tempat Lahir"),
    birthDate: Joi.date().required().label("Tanggal Lahir"),
    address: Joi.string().required().label("Alamat"),
    occupation: Joi.string().required().label("Pekerjaan"),
  };

  async componentDidMount() {
    bsCustomFileInput.init();
    try {
      const itemId = this.props.match.params.id;
      if (itemId === "new") return;

      if (itemId) {
        const {data} = await Axios.get(apiEndpoint + "/" + itemId);
        this.setState({data});
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.props.history.replace("/not-found");
      }
    }
  }

  handleChange = ({target: input}) => {
    const data = {...this.state.data};

    if (input.type === "file") {
      const reader = new FileReader();
      reader.readAsDataURL(input.files[0]);
      reader.onload = (e) => {
        data[input.name] = e.target.result;
        this.setState({data});
      }
    } else {
      data[input.name] = input.value;
      this.setState({data});
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) return;

    await StudentForm.saveForm(this.state.data);
    this.props.history.push("/students");
  };

  validate = () => {
    const options = {abortEarly: false, stripUnknown: true};
    const {error} = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  static saveForm(data) {
    if (data.id) {
      const body = {...data};
      return Axios.put(apiEndpoint + "/" + data.id, body);
    }
    return Axios.post(apiEndpoint, data);
  };

  render() {
    const {data, errors} = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Formulir Siswa</h3>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <img src={data.picture === null || data.picture === "" ? "/profile.png" : data.picture} alt={data.name}
                     className="img-thumbnail" width="150" height="150"/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <div className="custom-file">
                  <input id="picture"
                         name="picture"
                         value=""
                         onChange={this.handleChange}
                         type="file"
                         className="custom-file-input"/>
                  <label className="custom-file-label" htmlFor="picture">Pilih Foto</label>
                  {errors['picture'] && <small className="text-danger">{errors['picture']}</small>}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="studentCode">No. Induk</label>
                <input id="studentCode"
                       name="studentCode"
                       value={data.studentCode}
                       onChange={this.handleChange}
                       type="text"
                       disabled={data.id !== 0}
                       className="form-control" autoFocus/>
                {errors['studentCode'] && <small className="text-danger">{errors['studentCode']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="registrationDate">Tanggal Registrasi</label>
                <input id="registrationDate"
                       name="registrationDate"
                       value={data.registrationDate}
                       onChange={this.handleChange}
                       type="date"
                       className="form-control"/>
                {errors['registrationDate'] && <small className="text-danger">{errors['registrationDate']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="termNumber">Angkatan</label>
                <input id="termNumber"
                       name="termNumber"
                       value={data.termNumber}
                       onChange={this.handleChange}
                       type="number"
                       className="form-control"/>
                {errors['termNumber'] && <small className="text-danger">{errors['termNumber']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="sequenceNumber">Nomor Urut</label>
                <input id="sequenceNumber"
                       name="sequenceNumber"
                       value={data.sequenceNumber}
                       onChange={this.handleChange}
                       type="number"
                       className="form-control"/>
                {errors['sequenceNumber'] && <small className="text-danger">{errors['sequenceNumber']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="studentName">Nama Siswa</label>
                <input id="name"
                       name="name"
                       value={data.name}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
                {errors['name'] && <small className="text-danger">{errors['name']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="nickname">Nama Panggilan</label>
                <input id="nickname"
                       name="nickname"
                       value={data.nickname}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
                {errors['nickname'] && <small className="text-danger">{errors['nickname']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="birthPlace">Tempat Lahir</label>
                <input id="birthPlace"
                       name="birthPlace"
                       value={data.birthPlace}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
                {errors['birthPlace'] && <small className="text-danger">{errors['birthPlace']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="birthDate">Tanggal Lahir</label>
                <input id="birthDate"
                       name="birthDate"
                       value={data.birthDate}
                       onChange={this.handleChange}
                       type="date"
                       className="form-control"/>
                {errors['birthDate'] && <small className="text-danger">{errors['birthDate']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="phone">Telpon</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-mobile" aria-hidden="true"/></span>
                  </div>
                  <input id="phone"
                         name="phone"
                         value={data.phone}
                         onChange={this.handleChange}
                         type="text"
                         className="form-control"/>
                </div>
                {errors['phone'] && <small className="text-danger">{errors['phone']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-envelope-o" aria-hidden="true"/></span>
                  </div>
                  <input id="email"
                         name="email"
                         value={data.email}
                         onChange={this.handleChange}
                         type="email"
                         className="form-control"/>
                </div>
                {errors['email'] && <small className="text-danger">{errors['email']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="address">Alamat</label>
                <textarea id="address"
                          name="address"
                          value={data.address}
                          onChange={this.handleChange}
                          className="form-control" rows="3"/>
                {errors['address'] && <small className="text-danger">{errors['address']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="occupation">Pekerjaan</label>
                <input id="occupation"
                       name="occupation"
                       value={data.occupation}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
                {errors['occupation'] && <small className="text-danger">{errors['occupation']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="parentName">Nama Orang Tua</label>
                <input id="parentName"
                       name="parentName"
                       value={data.parentName}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control"/>
                {errors['parentName'] && <small className="text-danger">{errors['parentName']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="parentPhone">Telpon Orang Tua</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-mobile" aria-hidden="true"/></span>
                  </div>
                  <input id="parentPhone"
                         name="parentPhone"
                         value={data.parentPhone}
                         onChange={this.handleChange}
                         type="text"
                         className="form-control"/>
                </div>
                {errors['parentPhone'] && <small className="text-danger">{errors['parentPhone']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <select id="status"
                        name="status"
                        value={data.status}
                        onChange={this.handleChange}
                        className="form-control">
                  <option value="WAITING">Waiting List</option>
                  <option value="AKTIF">Aktif</option>
                  <option value="CUTI">Cuti</option>
                  <option value="SELESAI">Selesai</option>
                  <option value="BATAL">Batal</option>
                </select>
                {errors['status'] && <small className="text-danger">{errors['status']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="moreInfo">Info Tambahan</label>
                <textarea id="moreInfo"
                          name="moreInfo"
                          value={data.moreInfo === null ? "" : data.moreInfo}
                          onChange={this.handleChange}
                          className="form-control" rows="3"/>
                {errors['moreInfo'] && <small className="text-danger">{errors['moreInfo']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 my-4">
              <button type="submit" className="btn btn-primary mr-2">
                <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>Simpan
              </button>

              <button type="reset" className="btn btn-outline-secondary"
                      onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left mr-2" aria-hidden="true"/>Kembali
              </button>
            </div>
          </div>

        </form>
      </React.Fragment>
    );
  }
}

export default StudentForm;
