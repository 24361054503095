import React, {Component} from 'react';
import Axios from "axios";
import {apiURL} from "../../config";
import Joi from "joi-browser";
import localeId from "moment/locale/id";
import moment from "moment";
import swal from "@sweetalert/with-react/src/sweetalert";
import NumberFormat from "react-number-format";
import authenticationService from "../../services/authenticationService";
import {Lines} from 'react-preloaders';
import $ from 'jquery';
import Select from 'react-select';
import bsCustomFileInput from 'bs-custom-file-input';

const apiEndpoint = apiURL + "/activities";
const COURSE_PACKAGE_API_ENDPOINT = apiURL + "/coursePackages";
const TEACHER_AVAILABILITY_API_ENDPOINT = apiURL + "/teacher-service/availability";
const activityServiceEndpoint = apiURL + "/activity-service/registrationNumber";
const studentEndpoint = apiURL + "/students";

class ActivityFormDay extends Component {
  state = {
    loading: true,
    data: {
      id: 0,
      studentId: "",
      studentName: "",
      registrationDate: "",
      registrationNumber: 0,
      registrationNumberText: "",
      coursePackageId: "",
      programId: "",
      packageName: "",
      numberOfSessions: "",
      courseDay: "",
      courseTime: "",
      teacherId: "",
      teacherName: "",
      roomId: "",
      status: "",
      registrationFee: 0,
      price: 0,
      othersFee: 0,
      discountPercent: 0,
      discountAmount: 0,
      totalPayment: 0,
      payment: 0,
      outstanding: 0,
      outstandingActivity: 0,
      totalAmount: 0,
      paidSession: 0,
      teacherFee: 0,
      sessionWillBePaid: 0,
      activityDate: "",
      moreInfo: ""
    },
    selectedStudent: null,
    activitySchedules: [],
    students: [],
    studentsValueLabel: [],
    coursePackages: [],
    availableTeachers: [],
    availableDays: [],
    availableTimes: [],
    availableRooms: [],
    errors: {},
    payment: {
      id: 0,
      paymentNumber: "",
      activityId: 0,
      paymentTerm: "",
      paymentDate: "",
      paymentMethod: "",
      paymentLevel: "",
      paymentNote: "",
      paymentAmount: 0,
      othersFeeAmount: 0,
      discountAmount: 0,
      discountPercent: 0,
      totalPayment: 0,
      outstanding: 0,
      paidSession: 0,
      hasBeenPaid: 0
    },
    activityPayments: [],
    courseActivity: {
      id: 0,
      status: "",
      moreInfo: "",
      instructorInfo: ""
    },
    editedCourseActivityId: "",
    paymentSlip: {
      activity: {
        coursePackage: {},
        student: {},
        teacher: {}
      },
    },
    proofOfPayment: "",
    moveActivity: {
      moveActivityDate: "",
      moveActivityTime: "",
      moveTeacherId: "",
      moveRoomId: "",
      moveRoomName: ""
    },
    addActivity: {
      addActivityDate: "",
      addActivityTime: "",
      addTeacherId: "",
      addRoomId: "",
      addRoomName: ""
    },
    movedSchedule: {},
    teachers: [],
    paymentTerm: 0,
    leave: {
      leaveStartDate: "",
      leaveEndDate: "",
    },
    activityLeaves: [],
    scheduleCheck: [],
    activity: {},
    schedules: [],
    student: {}
  };

  schema = {
    id: Joi.number(),
    studentId: Joi.number().required().label("Siswa"),
    teacherId: Joi.number().required().label("Instruktur"),
    registrationDate: Joi.string().required().label("Tanggal Registrasi"),
    coursePackageId: Joi.number().required().label("Paket Kursus"),
    activityDate: Joi.string().required().label("Tanggal Mulai"),
    courseDay: Joi.number().required().label("Hari"),
    courseTime: Joi.number().required().label("Jam"),
    status: Joi.string().required().label("Status"),
    price: Joi.number().required().label("Biaya Kegiatan"),
    outstanding: Joi.number().required().label("Sisa Belum Terbayar"),
    teacherFee: Joi.number().positive().label("Biaya Instruktur"),
    paidSession: Joi.number().label("Pertemuan Terbayar")
  };

  handleSubmit = async e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) return;

    const dataId = await this.saveForm(this.state.data);
    const {data} = this.state;
    data.id = dataId;
    this.setState({data});
    // this.props.history.push("/activities");
  };

  validate = () => {
    const options = {abortEarly: false, stripUnknown: true};
    const {error} = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  async saveForm(data) {
    this.refs.btn.setAttribute("disabled", "disabled");

    let newId;

    if (data.id) {
      const body = {...data};
      body.courseTime = body.courseTime.toString().substring(0, 2);
      await Axios.put(apiEndpoint + "/" + data.id, body);
      newId = data.id;
    } else {
      const body = {...data};
      body.courseTime = body.courseTime.toString().substring(0, 2);
      const response = await Axios.post(apiEndpoint, body);
      newId = response.data.id;

      const resActivity = await Axios.get(apiEndpoint + "/" + newId);
      this.setState({data: resActivity.data});
    }

    let deepClone = JSON.parse(JSON.stringify(this.state.activitySchedules));
    let sequence = 1;
    for (const activitySchedule of deepClone) {
      activitySchedule.activityId = newId;
      if (activitySchedule.activitySeq !== 0) {
        activitySchedule.activitySeq = sequence++;
      }
      activitySchedule.studentId = this.state.data.studentId;
      activitySchedule.teacherId = activitySchedule.teacher.id;
      activitySchedule.startTime = activitySchedule.startTime.toString().substring(0, 2);
      activitySchedule.endTime = activitySchedule.endTime.toString().substring(0, 2);
      activitySchedule.roomId = activitySchedule.room.id !== null ? activitySchedule.room.id : this.state.data.roomId;
      if (activitySchedule.teacherFee !== 0.00) {
        activitySchedule.teacherFee = this.state.data.teacherFee;
      }
      delete activitySchedule.teacher;
      delete activitySchedule.student;
      delete activitySchedule.room;

      if (activitySchedule.id) {
        await Axios.put(apiURL + "/activitySchedules/" + activitySchedule.id, activitySchedule);
      } else {
        await Axios.post(apiURL + "/activitySchedules", activitySchedule);
      }
    }

    await this.loadScheduleAfterSaved(newId);

    for (const activityLeave of this.state.activityLeaves) {
      if (activityLeave.id) {
        await Axios.put(apiURL + "/activityLeaves/" + activityLeave.id, activityLeave);
      } else {
        await Axios.post(apiURL + "/activityLeaves", activityLeave);
      }
    }

    this.refs.btn.removeAttribute("disabled");
    return newId;
  }

  async loadScheduleAfterSaved(activityId) {
    /* Load schedules after saved */
    if (this.state.activitySchedules.length !== 0) {
      const response1 = await Axios.get(apiEndpoint + "/" + activityId + "/activitySchedules");
      const {activitySchedules} = response1.data._embedded;
      activitySchedules.forEach(function (item) {
        item.startTime = item.startTime.toString().padStart(2, '0') + ":00";
        item.endTime = item.endTime.toString().padStart(2, '0') + ":00";
      });
      this.setState({activitySchedules});
    }
  }

  async componentDidMount() {
    bsCustomFileInput.init();
    try {
      await this.populateStudents();
      await this.populateCourses();

      const itemId = this.props.match.params.id;
      if (itemId === "new") {
        const {data} = this.state;
        data.registrationDate = moment(new Date()).format("YYYY-MM-DD");

        const roomResponse = await Axios.get(apiURL + "/rooms");
        const availableRooms = roomResponse.data._embedded.rooms;
        this.setState({availableRooms});

        const {roomName, date, day, time} = this.props.location.state;
        availableRooms.forEach((e) => {
          if (e.roomName === roomName) {
            data.roomId = e.id;
          }
        });

        data.activityDate = date;
        data.courseDay = day;
        data.courseTime = time;

        this.setState({data});
        this.setState({loading: false});
        return;
      }

      if (itemId) {
        const response = await Axios.get(apiEndpoint + "/" + itemId);
        const {data} = response;

        const roomResponse = await Axios.get(apiURL + "/rooms");
        const availableRooms = roomResponse.data._embedded.rooms;
        this.setState({availableRooms});

        const activityLeaveLink = apiURL + "/activities/" + itemId + "/activityLeaves";
        const activityLeaveRes = await Axios.get(activityLeaveLink);
        const {activityLeaves} = activityLeaveRes.data._embedded;
        this.setState({activityLeaves});

        const studentLink = apiURL + "/activities/" + itemId + "/student?projection=studentCodeProjection";
        const studentRes = await Axios.get(studentLink);
        data.studentId = studentRes.data.id;

        const selectedStudent = {"value": data.studentId, "label": studentRes.data.studentCode + " - " + studentRes.data.name};
        this.setState({selectedStudent});

        const courseLink = apiURL + "/activities/" + itemId + "/coursePackage";
        const courseRes = await Axios.get(courseLink);
        data.coursePackageId = courseRes.data.id;
        data.programId = courseRes.data.program.id;
        data.numberOfSessions = courseRes.data.numberOfSessions;

        // Load available teacher
        if (data.coursePackageId !== "") {
          this.setState({
            availableTeachers: [{
              "id": data.teacherId,
              "teacherCode": "",
              "teacherName": data.teacherName
            }]
          });
        }

        const teacherLink = apiURL + "/activities/" + itemId + "/teacher?projection=teacherCodeProjection";
        const teacherRes = await Axios.get(teacherLink);
        data.teacherId = teacherRes.data.id;
        this.setState({data});

        const response1 = await Axios.get(apiEndpoint + "/" + itemId + "/activitySchedules");
        const {activitySchedules} = response1.data._embedded;
        activitySchedules.forEach(function (item) {
          item.startTime = item.startTime.toString().padStart(2, '0') + ":00";
          item.endTime = item.endTime.toString().padStart(2, '0') + ":00";
        });
        this.setState({activitySchedules});
        this.prepareDataForAddAndMoveScheduleDialog();

        const response2 = await Axios.get(apiEndpoint + "/" + itemId + "/activityPayments");
        const {activityPayments} = response2.data._embedded;
        this.setState({activityPayments});
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.props.history.replace("/not-found");
      }
    }

    if (this.state.data.registrationNumber !== "") {
      try {
        let {data} = await Axios.get(activityServiceEndpoint + "?regnum=" + this.state.data.registrationNumberText);
        const response = await Axios.get(apiEndpoint + "/" + data);
        const activity = response.data;
        this.setState({activity});
        if (activity._embedded.activitySchedules) {
          this.setState({schedules: activity._embedded.activitySchedules})
          const resStudent = await Axios.get(studentEndpoint + "/" + activity.studentId);
          const student = resStudent.data;
          this.setState({student});
        }
      } catch (e) {
      }
    }

    this.setState({loading: false});
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
  };

  async prepareDataForAddAndMoveScheduleDialog() {
    let activityScheduleLength = this.state.activitySchedules.length;
    if (activityScheduleLength > 0) {
      let lastActivitySchedule = this.state.activitySchedules[activityScheduleLength - 1];

      let newActivityDate = moment(lastActivitySchedule.activityDate, "YYYY-MM-DD");
      newActivityDate = newActivityDate.add(7, 'days');

      const {moveActivity} = this.state;
      moveActivity.moveActivityDate = newActivityDate.format("YYYY-MM-DD");
      moveActivity.moveActivityTime = lastActivitySchedule.startTime.substring(0, 2);
      moveActivity.moveTeacherId = lastActivitySchedule.teacher.id;
      moveActivity.moveRoomId = lastActivitySchedule.room.id;
      moveActivity.moveRoomName = lastActivitySchedule.room.roomName;
      this.setState({moveActivity});

      const {addActivity} = this.state;
      addActivity.addActivityDate = newActivityDate.format("YYYY-MM-DD");
      addActivity.addActivityTime = lastActivitySchedule.startTime.substring(0, 2);
      addActivity.addTeacherId = lastActivitySchedule.teacher.id;
      addActivity.addRoomId = lastActivitySchedule.room.id;
      addActivity.addRoomName = lastActivitySchedule.room.roomName;
      this.setState({addActivity});

      const url = `${apiURL}/teachers?projection=teacherCodeProjection`;
      const responses = await Axios.get(url);
      const {teachers} = responses.data._embedded;
      this.setState({teachers});
    }
  }

  async populateStudents() {
    try {
      const url = apiURL + "/activity-service/students";
      await Axios.get(url).then((response) => {
        this.setState({students: response.data});
        const studentsValueLabel = [];
        response.data.forEach(function (student) {
          studentsValueLabel.push({value: student.id, label: student.studentCode + " - " + student.name});
        });
        this.setState({studentsValueLabel: studentsValueLabel});
      });
    } catch (e) {
    }
  };

  async populateCourses() {
    try {
      const url = apiURL + "/coursePackages?projection=coursePackageCodeProjection&sort=packageName,asc";
      const response = await Axios.get(url);
      const {coursePackages} = response.data._embedded;
      this.setState({coursePackages});
    } catch (e) {
    }
  };

  handleStudentSelectionChange = async (selectedStudent) => {
    this.setState({selectedStudent});
    const data = {...this.state.data};

    if (selectedStudent !== null) {
      const response = await Axios.get(apiURL + "/students/" + selectedStudent.value);
      data.studentId = response.data.id;
      data.studentName = response.data.name;
    } else {
      selectedStudent = null;
      data.studentId = "";
      this.setState({selectedStudent});
    }
    this.setState({data});
  };

  handleChange = ({currentTarget: input}) => {
    const data = {...this.state.data};
    data[input.name] = input.value;

    this.setState({data: data}, async () => {
        const {data} = this.state;

        if (input.name === "teacherId") {
          const response = await Axios.get(apiURL + "/teachers/" + data.teacherId);
          data.teacherName = response.data.teacherName;
          this.setState({data});
        }

        if (data.coursePackageId !== "" && input.name === "coursePackageId") {
          const response = await Axios.get(`${COURSE_PACKAGE_API_ENDPOINT}/${data.coursePackageId}?projection=coursePackageProjection`);
          data.programId = response.data.program.id;
          data.packageName = response.data.packageName;
          data.price = response.data.price;
          data.totalAmount = data.price;
          data.outstanding = response.data.price;
          data.outstandingActivity = response.data.price;
          data.numberOfSessions = response.data.numberOfSessions;
          data.teacherFee = response.data.teacherFee;
          this.setState({data});
        }

        if (data.coursePackageId !== "" && input.name === "coursePackageId") {
          const response = await Axios.get(TEACHER_AVAILABILITY_API_ENDPOINT, {
            params: {
              "coursePackageId": data.coursePackageId,
              "day": data.courseDay,
              "time": data.courseTime,
              "startDate": moment(data.activityDate).format("YYYY-MM-DD")
            }
          });
          this.setState({availableTeachers: response.data});
        }
      }
    );
  };

  async checkIfScheduleDatesAreClear() {
    const {data} = this.state;
    let {scheduleCheck} = this.state;
    scheduleCheck.length = 0;

    let cleared = 0;
    let activityStartDate = moment(data.activityDate, "YYYY-MM-DD");
    for (let i = 0; i < data.numberOfSessions; i++) {
      // Check untuk melihat hari berikut-nya bukan hari libur.
      // Looping sampai hari yang bukan hari libur ditemukan.
      let dataId = undefined;
      do {
        const response = await Axios.get(apiURL + "/holiday-service/check?date=" + activityStartDate.format("YYYY-MM-DD"));
        dataId = response.data.id;
        if (dataId !== undefined) {
          activityStartDate = activityStartDate.add(7, 'days');
        }
      } while (dataId !== undefined);

      const scheduleResponse = await Axios.get(apiURL + "/activity-service/schedule-availability?activityDate=" +
        moment(activityStartDate).format("YYYY-MM-DD") + "&startTime=" + data.courseTime + "&roomId=" + data.roomId);

      const scheduleId = scheduleResponse.data.id;
      if (scheduleId === undefined || scheduleId === null) {
        cleared++;
      }
      const activityId = scheduleResponse.data.activityId;
      if (scheduleId !== undefined && scheduleId !== null && activityId !== undefined && activityId !== null) {
        const registrationNumberText = await Axios.get(apiURL + "/activity-service/find-registration-number/" + activityId);
        scheduleCheck.push({
          "activityDate": moment(activityStartDate).format("ddd, DD-MMM-YYYY"),
          "startTime": data.courseTime,
          "registration": registrationNumberText.data
        });
      }
      activityStartDate = activityStartDate.add(7, 'days');
    }
    return cleared === data.numberOfSessions;
  }

  handleGenerateSchedule = async () => {
    const {data} = this.state;
    let numberOfSessions = data.numberOfSessions;

    if (data.teacherId === "") {
      swal("Silahkan pilih instruktur terlebih dahulu.", {icon: "error"});
      return;
    }

    if (await this.checkIfScheduleDatesAreClear()) {
      if (data.teacherId !== "") {
        const activitySchedules = [];
        let activityStartDate = moment(this.state.data.activityDate, "YYYY-MM-DD");
        const teacher = await Axios.get(apiURL + "/teachers/" + this.state.data.teacherId);
        const room = await Axios.get(apiURL + "/rooms/" + this.state.data.roomId);
        for (let i = 0; i < numberOfSessions; i++) {
          activitySchedules.push({
            id: (this.state.activitySchedules.length !== 0 && this.state.activitySchedules[i].id != null) ? this.state.activitySchedules[i].id : null,
            activitySeq: i + 1,
            activityDate: activityStartDate.format("YYYY-MM-DD"),
            startTime: activityStartDate.hour(this.state.data.courseTime).format("HH:mm"),
            endTime: activityStartDate.hour(parseInt(this.state.data.courseTime) + 1).format("HH:mm"),
            status: "",
            teacher: {id: this.state.data.teacherId, teacherName: teacher.data.teacherName},
            room: {id: this.state.data.roomId, roomName: room.data.roomName},
            roomId: this.state.data.roomId,
            actualStart: "",
            startBy: "",
            actualFinish: "",
            finishBy: "",
            moreInfo: "",
            instructorInfo: "",
            excluded: false
          });
          activityStartDate = activityStartDate.add(7, 'days');

          // Check untuk melihat hari berikut-nya bukan hari libur.
          // Looping sampai hari yang bukan hari libur ditemukan.
          let dataId = undefined;
          do {
            const response = await Axios.get(apiURL + "/holiday-service/check?date=" + activityStartDate.format("YYYY-MM-DD"));
            dataId = response.data.id;
            if (dataId !== undefined) {
              activityStartDate = activityStartDate.add(7, 'days');
            }
          } while (dataId !== undefined);
        }
        this.setState({activitySchedules}, () => {
          this.prepareDataForAddAndMoveScheduleDialog();
        });
      }
    } else {
      const {scheduleCheck} = this.state;
      swal(
        <React.Fragment>
          <div>
            <p>Poof! Jadwal tidak berhasil dibuat karena sudah terpakai pada aktivitas berikut:</p>
            <table className="table table-bordered table-striped table-hover table-sm">
              <thead className="thead-light">
              <tr>
                <th>Tanggal</th>
                <th>Nomor Registrasi</th>
              </tr>
              </thead>
              <tbody>
              {scheduleCheck.map((item, index) => (
              <tr key={index}>
                <td>{item.activityDate} {item.startTime}:00</td>
                <td>{item.registration}</td>
              </tr>
              ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>, {icon: "error"}
        );
    }
  };

  scheduleCannotBeClearer() {
    for (let index = 0; index < this.state.activitySchedules.length; index++) {
      let activitySchedule = this.state.activitySchedules[index];
      if (activitySchedule.status !== null && activitySchedule.status !== "") {
        return true;
      }
    }
  };

  handleClearSchedule = async () => {
    const activitySchedules = this.state.activitySchedules;
    let canBeCleared = true;
    activitySchedules.forEach((activitySchedule) => {
      if (activitySchedule.status !== null && activitySchedule.status !== "") {
        canBeCleared = false;
      }
    });

    if (canBeCleared) {
      swal({
        title: "Konfirmasi",
        text: "Apakah anda yakin akan membersihkan jadwal pada aktivitas ini?",
        icon: "warning",
        buttons: ["Batal", "YA"],
        dangerMode: true
      }).then(async (willDelete) => {
        if (willDelete) {
          const activitySchedules = [];
          this.setState({activitySchedules});

          const response = await Axios.delete(apiURL + "/activity-service/clear-schedules/" + this.state.data.id);
          swal("Poof! Jadwal aktivitas sudah berhasil dibersihkan!", {icon: "success"});
        }
      });
    } else {
      swal("Data jadwal tidak bisa dihapus, jadwal sudah digunakan.", {icon: "info"});
    }
  };

  handleSaveDetailPayment = async (e) => {
    const body = {...this.state.data};
    body.courseTime = body.courseTime.toString().substring(0, 2);
    await Axios.put(apiEndpoint + "/" + this.state.data.id, body);
  };

  handleSavePayment = async (e) => {
    if (this.state.payment.totalPayment > this.state.data.outstanding) {
      swal("Pembayaran tidak disimpan karena melebihi sisa belum terbayar. Silahkan diulang kembali", {icon: "info"});
      return;
    }

    Axios.post(apiURL + "/activityPayments", this.state.payment)
      .then(async (response) => {
        if (response.status === 201) {
          const data = {...this.state.data};
          data.outstanding = data.outstanding - this.state.payment.paymentAmount - this.state.payment.othersFeeAmount;
          data.outstandingActivity = data.outstandingActivity - this.state.payment.paymentAmount;
          data.totalPayment = data.totalPayment + this.state.payment.paymentAmount + this.state.payment.othersFeeAmount;
          data.courseTime = this.state.data.courseTime.toString().substring(0, 2);
          data.paidSession = this.state.data.paidSession + this.state.data.sessionWillBePaid;
          this.setState({data});

          await Axios.put(apiEndpoint + "/" + this.state.data.id, data)

          const response2 = await Axios.get(apiEndpoint + "/" + this.state.data.id + "/activityPayments");
          const {activityPayments} = response2.data._embedded;
          this.setState({activityPayments});
        }
      });
  };

  handleInitPaymentDetail = async (e) => {
    const {data} = this.state;
    if (data.totalPayment === 0) {
      data.totalAmount = (data.registrationFee + data.price + data.othersFee) - data.discountAmount;
      data.outstanding = data.totalAmount - data.totalPayment;
      data.outstandingActivity = data.price - (data.price * data.discountPercent / 100);
      data.sessionWillBePaid = data.numberOfSessions - data.paidSession;
      this.setState({data});
    }
  };

  handlePaymentDetailFormChange = ({target: input}) => {
    const data = {...this.state.data};
    if (input.type === "number") {
      data[input.name] = parseInt(input.value, 10);
    } else {
      data[input.name] = input.value;
    }

    this.setState({data}, () => {
      const {data} = this.state;
      if (input.name === "discountPercent" || input.name === "registrationFee" || input.name === "othersFee") {
        let totalFee = data.registrationFee + data.price + data.othersFee;
        data.discountAmount = (data.discountPercent * totalFee) / 100;
        data.totalAmount = totalFee - data.discountAmount;
        data.outstanding = data.totalAmount - data.totalPayment;
        data.outstandingActivity = data.price - (data.price * data.discountPercent / 100);
        this.setState({data});
      }
    });
  };

  handleInitPayment = async (e) => {
    const {payment} = this.state;
    const {data} = this.state;
    payment.activityId = this.state.data.id;

    const url = apiURL + "/activity-service/nextPaymentTerm?activityId=" + this.state.data.id;
    const response = await Axios.get(url);
    payment.paymentTerm = response.data;

    payment.paymentDate = moment(new Date()).format("YYYY-MM-DD");
    payment.paymentLevel = "1";
    payment.paymentMethod = "CASH";
    payment.paymentNote = "";
    payment.paymentAmount = data.outstandingActivity;
    payment.othersFeeAmount = data.outstandingActivity !== 0 ? data.outstanding - data.outstandingActivity : data.outstanding;
    payment.discountPercent = 0;
    payment.discountAmount = 0;
    payment.totalPayment = payment.paymentAmount + payment.othersFeeAmount;
    payment.outstanding = data.outstanding - (payment.paymentAmount + payment.othersFeeAmount);
    payment.hasBeenPaid = data.totalPayment;

    data.sessionWillBePaid = data.numberOfSessions - data.paidSession;
    this.setState({data});
    this.setState({payment});
  };

  handlePaymentFormChange = ({target: input}) => {
    const payment = {...this.state.payment};
    const data = {...this.state.data};

    if (input.type === "file") {
      const reader = new FileReader();
      reader.readAsDataURL(input.files[0]);
      reader.onload = (e) => {
        payment[input.name] = e.target.result;
        this.setState({payment});
      }
    } else if (input.type === "number") {
      payment[input.name] = parseInt(input.value, 10);
    } else {
      payment[input.name] = input.value;
    }

    this.setState({payment}, () => {
      const {payment} = this.state;

      if (input.name === "paymentLevel") {
        payment.paymentAmount = data.outstandingActivity / parseInt(payment.paymentLevel, 10);
        payment.totalPayment = payment.paymentAmount + payment.othersFeeAmount - payment.discountAmount;
        //payment.outstanding = data.outstandingActivity - payment.paymentAmount + data.outstanding;
        payment.outstanding = data.outstanding - (payment.paymentAmount + payment.othersFeeAmount);

        if (payment.discountPercent !== undefined || payment.discountPercent !== 0) {
          payment.discountAmount = Math.round((payment.discountPercent * (payment.paymentAmount + payment.othersFeeAmount)) / 100);
          payment.totalPayment = payment.paymentAmount + payment.othersFeeAmount - payment.discountAmount;
        }
        this.setState({payment});

        let sessionWillBePaid = 0;
        let remainingSessionToBePaid = data.numberOfSessions - data.paidSession - sessionWillBePaid;
        sessionWillBePaid = remainingSessionToBePaid / parseInt(payment.paymentLevel, 10);
        data.sessionWillBePaid = sessionWillBePaid;
        this.setState({data});
      }

      if (input.name === "discountPercent" || input.name === "othersFeeAmount") {
        payment.discountAmount = Math.round((payment.discountPercent * (payment.paymentAmount + payment.othersFeeAmount)) / 100);
        payment.totalPayment = payment.paymentAmount + payment.othersFeeAmount - payment.discountAmount;
        payment.outstanding = data.outstanding - (payment.paymentAmount + payment.othersFeeAmount);
        this.setState({payment});
      }
    });
  };

  updateActivityStatus(data) {
    const activitySchedules = this.state.activitySchedules;
    const filtered = activitySchedules.filter(function (activitySchedule) {
      return activitySchedule.id !== data.id;
    });

    filtered.push({
      id: data.id,
      activitySeq: data.activitySeq,
      activityDate: data.activityDate,
      startTime: data.startTime + ":00",
      endTime: data.endTime + ":00",
      status: data.status,
      teacher: {id: data.teacherId, teacherName: data.teacherName},
      room: {id: data.roomId, roomName: data.roomName},
      moreInfo: data.moreInfo,
      instructorInfo: data.instructorInfo,
      excluded: data.excluded,
      replacedScheduleId: data.replacedScheduleId,
      actualStart: data.actualStart,
      startBy: data.startBy,
      actualFinish: data.actualFinish,
      finishBy: data.finishBy,
      additional: data.additional
    });

    filtered.sort(function (a, b) {
      if (a.activityDate > b.activityDate) {
        return 1;
      } else if (a.activityDate < b.activityDate) {
        return -1;
      }
      return 0;
    });

    this.setState({activitySchedules: filtered});
  }

  handleCourseActivityEdit = (item) => {
    const editedCourseActivityId = item.id;
    this.setState({editedCourseActivityId: editedCourseActivityId}, async () => {
      const response = await Axios.get(apiURL + "/activitySchedules/" + editedCourseActivityId);
      const {data} = response;

      const courseActivity = {...this.state.courseActivity};
      courseActivity.id = data.id;
      courseActivity.status = data.status;
      courseActivity.moreInfo = data.moreInfo === null ? "" : data.moreInfo;
      courseActivity.instructorInfo = data.instructorInfo === null ? "" : data.instructorInfo;
      this.setState({courseActivity});

    });
  };

  handleCourseActivityFormChange = ({target: input}) => {
    const courseActivity = {...this.state.courseActivity};
    courseActivity[input.name] = input.value;
    this.setState({courseActivity});
  };

  handleAddCourseActivity = async (e) => {
    const response = await Axios.post(apiURL + "/activity-service/schedule-info/" + this.state.editedCourseActivityId,
      {"moreInfo": this.state.courseActivity.moreInfo, "instructorInfo": this.state.courseActivity.instructorInfo});
    const {data} = response;
    this.updateActivityStatus(data);

    const courseActivity = {...this.state.courseActivity};
    courseActivity.moreInfo = "";
    courseActivity.instructorInfo = "";
    this.setState({courseActivity});
  };

  handleViewProofOfPayment = async (item) => {
    this.setState({proofOfPayment: item.proofOfPayment});
  };

  handlePrintPreparation = async (item) => {
    this.setState({paymentSlip: item}, () => {
      const paymentTerm = item.paymentTerm;
      this.setState({paymentTerm})
    });
  };

  handlePrintSlip = () => {
    function printElement(elem) {
      let domClone = elem.cloneNode(true);
      let printSection = document.getElementById("printSection");

      if (!printSection) {
        printSection = document.createElement("div");
        printSection.id = "printSection";
        document.body.appendChild(printSection);
      } else {
        printSection.innerHTML = "";
      }
      printSection.appendChild(domClone);
    }

    printElement(document.getElementById("paymentSlipContent"));
    window.print();
  };

  onClickSaveButton = () => {
    this.refs.form.dispatchEvent(new Event("submit"));
  };

  handleStartStopSchedule = (item) => {
    let text = "";
    if (item.status === null || item.status === "") {
      text = "Apakah anda yakin untuk memulai/membuka aktivitas ini?";
    } else if (item.status === "1") {
      text = "Aktivitas sedang berlangsung. Apakah anda yakin akan menyelesaikan/menutup aktivitas ini?"
    } else if (item.status === "2") {
      text = "Apakah anda yakin akan membatalkan aktivitas ini?"
    }

    swal({
      title: "Konfirmasi",
      text: text,
      icon: "warning",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (isConfirm) => {
      if (isConfirm) {
        let payload = {};
        if (item.status == null || item.status === "") {
          payload = {
            "status": "1",
            "actualStart": moment(new Date()).utc(true).format("YYYY-MM-DDTHH:mm:ss"),
            "startBy": authenticationService.getUsername()
          };
        } else if (item.status === "1") {
          payload = {
            "status": "2",
            "actualFinish": moment(new Date()).utc(true).format("YYYY-MM-DDTHH:mm:ss"),
            "finishBy": authenticationService.getUsername()
          };
        } else if (item.status === "2") {
          payload = {
            "status": "X"
          };
        }
        const response = await Axios.post(apiURL + "/activity-service/schedule-status/" + item.id, payload);
        const {data} = response;
        this.updateActivityStatus(data);

        let message = "";
        if (data.status === "1") {
          message = "Aktivitas telah dimulai."
        } else if (data.status === "2") {
          message = "Aktivitas telah selesai."
        } else if (data.status === "") {
          message = "Aktivitas telah dibatalkan."
        }
        swal(message, {icon: "success"});
      }
    });
  };

  handleChangeActivityTeacher = async ({target: input}, item) => {
    const {teachers} = this.state;
    const teacher = teachers.filter(function (teacher) {
      return teacher.id === parseInt(input.value);
    });

    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin akan mengganti instruktur aktivitas ini dengan " + teacher[0].teacherName + "?",
      icon: "warning",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willChange) => {
      if (willChange) {
        const payload = {"teacherId": teacher[0].id};
        const response = await Axios.post(apiURL + "/activity-service/schedule-teacher/" + item.id, payload);
        const {data} = response;
        this.updateActivityStatus(data);
        swal("Woohoo, instruktur aktivitas berhasil diupdate.", {icon: "success"});
      }
    });
  };

  handleChangeActivityRoom = async ({target: input}, item) => {
    const {availableRooms} = this.state;
    const room = availableRooms.filter(function (room) {
      return room.id === parseInt(input.value);
    });

    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin akan mengganti studio aktivitas ini dengan " + room[0].roomName + "?",
      icon: "warning",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willChange) => {
      if (willChange) {
        const payload = {
          "roomId": room[0].id,
          "activityDate": item.activityDate,
          "startTime": item.startTime.toString().substring(0, 2)
        };
        try {
          const response = await Axios.post(apiURL + "/activity-service/schedule-room/" + item.id, payload);
          const {data} = response;
          this.updateActivityStatus(data);
          swal("Woohoo, studio aktivitas berhasil diupdate.", {icon: "success"});
        } catch (e) {
          if (e.response && e.response.status === 404) {
            swal(`Aktivitas tidak bisa pindah studio. Studio ${room[0].roomName} pada tanggal ${moment(item.activityDate).format("ddd, DD-MMM-YYYY")} telah terpakai.`, {icon: "error"});
          }
        }
      }
    });
  };

  initMoveSchedule = (item) => {
    const movedSchedule = item;
    this.setState({movedSchedule});
    this.prepareDataForAddAndMoveScheduleDialog();
  };

  handleMoveChange = async ({target: input}) => {
    const moveActivity = {...this.state.moveActivity};
    moveActivity[input.name] = input.value;

    if (input.name === "moveRoomId") {
      const {availableRooms} = this.state;
      const room = availableRooms.filter(function (room) {
        return room.id + "" === input.value;
      });
      moveActivity['moveRoomName'] = room[0].roomName;
    }
    this.setState({moveActivity});
  };

  handleProcessMoveSchedule = async (e) => {
    const moveActivity = {...this.state.moveActivity};

    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin akan memindahkan jadwal ke hari " + moment(moveActivity.moveActivityDate).format("dddd, DD-MMM-YYYY") + " jam " + moveActivity.moveActivityTime.toString().padStart(2, '0') + ":00, studio " + moveActivity.moveRoomName + "?",
      icon: "warning",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willMove) => {
      if (willMove) {
        const holidayResponse = await Axios.get(apiURL + "/holiday-service/check?date=" + moment(moveActivity.moveActivityDate).format("YYYY-MM-DD"));
        const dataId = holidayResponse.data.id;

        if (dataId !== undefined) {
          swal("Jadwal yang anda pilih hari " + moment(moveActivity.moveActivityDate).format("dddd, DD-MMM-YYYY") +
            " jatuh pada hari libur. Jadwal tidak bisa dipindahkan.", {icon: "error"});
        } else {
          const scheduleResponse = await Axios.get(apiURL + "/activity-service/schedule-availability?activityDate=" +
            moment(moveActivity.moveActivityDate).format("YYYY-MM-DD") +
            "&startTime=" + moveActivity.moveActivityTime + "&roomId=" + moveActivity.moveRoomId);

          const scheduleId = scheduleResponse.data.id;
          const activityId = scheduleResponse.data.activityId;
          if (scheduleId !== undefined && activityId !== undefined) {
            const registrationNumberText = await Axios.get(apiURL + "/activity-service/find-registration-number/" + activityId);
            swal("Jadwal yang anda pilih hari " + moment(moveActivity.moveActivityDate).format("dddd, DD-MMM-YYYY") +
              ", jam " + moveActivity.moveActivityTime.toString().padStart(2, '0') + ":00, studio " + moveActivity.moveRoomName + " sudah digunakan oleh aktivitas dengan nomor " +
              "registrasi " + registrationNumberText.data + ". Jadwal tidak bisa dipindahkan.", {icon: "error"});
          } else {
            const movedSchedule = {...this.state.movedSchedule};
            movedSchedule.status = "3";
            movedSchedule.excluded = true;
            this.setState({movedSchedule});

            const currentSchedules = this.state.activitySchedules;
            currentSchedules.forEach(function (activitySchedule) {
              if (activitySchedule.id === movedSchedule.id) {
                activitySchedule.excluded = true;
                activitySchedule.status = "3"; // Pindah
                activitySchedule.moreInfo = "Dipindahkan ke hari " + moment(moveActivity.moveActivityDate).format("dddd") +
                  ", tanggal " + moment(moveActivity.moveActivityDate).format("DD-MMM-YYYY") + " pada jam " +
                  moveActivity.moveActivityTime.toString().padStart(2, '0') + ":00, studio " + moveActivity.moveRoomName + ".";
                activitySchedule.activitySeq = 0;

                const payload = {
                  "excluded": activitySchedule.excluded,
                  "status": activitySchedule.status,
                  "moreInfo": activitySchedule.moreInfo,
                  "activitySeq": activitySchedule.activitySeq
                };

                Axios.post(apiURL + "/activity-service/schedule-status/" + activitySchedule.id, payload);
              }
            });

            let newActivity = JSON.parse(JSON.stringify(this.state.activitySchedules[this.state.activitySchedules.length - 1]));

            newActivity.id = null;
            newActivity.activityDate = moveActivity.moveActivityDate;
            newActivity.startTime = moveActivity.moveActivityTime.toString().padStart(2, '0') + ":00";
            newActivity.endTime = (parseInt(moveActivity.moveActivityTime) + 1).toString().padStart(2, '0') + ":00";

            newActivity.teacher.id = moveActivity.moveTeacherId;
            newActivity.room.id = moveActivity.moveRoomId;

            const response = await Axios.get(apiURL + "/teachers/" + moveActivity.moveTeacherId);
            newActivity.teacher.teacherName = response.data.teacherName;
            newActivity.teacher.teacherCode = response.data.teacherCode;

            const roomResponse = await Axios.get(apiURL + "/rooms/" + moveActivity.moveRoomId);
            newActivity.room.roomName = roomResponse.data.roomName;
            newActivity.room.roomCode = roomResponse.data.roomCode;

            newActivity.status = "";
            newActivity.activitySeq = null;
            newActivity.exclude = false;
            newActivity.replacedScheduleId = movedSchedule.id;
            newActivity.additional = false;
            newActivity.moreInfo = "";
            newActivity.instructorInfo = "";
            newActivity.actualStart = "";
            newActivity.startBy = "";
            newActivity.actualFinish = "";
            newActivity.finishBy = "";

            const activitySchedules = this.state.activitySchedules;
            activitySchedules.push(newActivity);
            activitySchedules.sort(function (a, b) {
              if (a.activityDate > b.activityDate) {
                return 1;
              } else if (a.activityDate < b.activityDate) {
                return -1;
              }
              return 0;
            });
            this.setState({activitySchedules}, () => {
              this.saveForm(this.state.data);
              swal("Poof! Jadwal aktivitas sudah berhasil dipindahkan. Mohon untuk menyimpan data kembali.", {icon: "success"});
            });
          }
        }
      }
    });
  };

  undoMoveSchedule = (item) => {
    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin akan membatalkan pemindahan jadwal ini?",
      icon: "warning",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willCancel) => {
      if (willCancel) {
        await Axios.delete(apiURL + "/activity-service/delete-replacement-schedule/" + item.id)
          .then(async (response) => {
            if (response.status === 200) {
              const payload = {
                "excluded": false,
                "status": "",
                "moreInfo": "",
                "activitySeq": -1
              };
              await Axios.post(apiURL + "/activity-service/schedule-status/" + item.id, payload)
                .then((response) => {
                  if (response.status === 200) {
                    this.loadScheduleAfterSaved(this.state.data.id);
                    this.saveForm(this.state.data);
                    swal("Pemindahan jadwal sudah berhasil dibatalkan.", {icon: "success"});
                  }
                });
            }
          });
      }
    });
  };

  initAddSchedule = () => {
    this.prepareDataForAddAndMoveScheduleDialog();
  };

  handleAddScheduleChange = async ({target: input}) => {
    const addActivity = {...this.state.addActivity};
    addActivity[input.name] = input.value;

    if (input.name === "addRoomId") {
      const {availableRooms} = this.state;
      const room = availableRooms.filter(function (room) {
        return room.id + "" === input.value;
      });
      addActivity['addRoomName'] = room[0].roomName;
    }
    this.setState({addActivity});
  };

  handleProcessAddSchedule = async (e) => {
    const addActivity = {...this.state.addActivity};

    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin akan menambahkan jadwal pada hari " + moment(addActivity.addActivityDate).format("dddd, DD-MMM-YYYY") + " jam " + addActivity.addActivityTime + ":00, studio " + addActivity.addRoomName + "?",
      icon: "warning",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willAdd) => {
      if (willAdd) {
        const holidayResponse = await Axios.get(apiURL + "/holiday-service/check?date=" + moment(addActivity.addActivityDate).format("YYYY-MM-DD"));
        const dataId = holidayResponse.data.id;

        if (dataId !== undefined) {
          swal("Jadwal yang anda pilih hari " + moment(addActivity.addActivityDate).format("dddd, DD-MMM-YYYY") +
            " jatuh pada hari libur. Jadwal tidak bisa ditambahkankan.", {icon: "error"});
        } else {
          const scheduleResponse = await Axios.get(apiURL + "/activity-service/schedule-availability?activityDate=" +
            moment(addActivity.addActivityDate).format("YYYY-MM-DD") +
            "&startTime=" + addActivity.addActivityTime + "&roomId=" + this.state.data.roomId);

          const scheduleId = scheduleResponse.data.id;
          const activityId = scheduleResponse.data.activityId;
          if (scheduleId !== undefined) {
            const registrationNumberText = await Axios.get(apiURL + "/activity-service/find-registration-number/" + activityId);
            swal("Jadwal yang anda pilih hari " + moment(addActivity.addActivityDate).format("dddd, DD-MMM-YYYY") +
              ", jam " + addActivity.addActivityTime + ":00, studio " + addActivity.addRoomName + " sudah digunakan oleh aktivitas dengan nomor " +
              "registrasi " + registrationNumberText.data + ". Jadwal tidak bisa ditambahkan.", {icon: "error"});
          } else {
            let newActivity = JSON.parse(JSON.stringify(this.state.activitySchedules[this.state.activitySchedules.length - 1]));

            const addActivity = {...this.state.addActivity};
            newActivity.id = null;
            newActivity.activityDate = addActivity.addActivityDate;
            newActivity.startTime = addActivity.addActivityTime + ":00";
            newActivity.endTime = (parseInt(addActivity.addActivityTime) + 1) + ":00";
            newActivity.teacher.id = addActivity.addTeacherId;
            newActivity.room.id = addActivity.addRoomId;

            const response = await Axios.get(apiURL + "/teachers/" + addActivity.addTeacherId);
            newActivity.teacher.teacherName = response.data.teacherName;
            newActivity.teacher.teacherCode = response.data.teacherCode;

            const roomResponse = await Axios.get(apiURL + "/rooms/" + addActivity.addRoomId);
            newActivity.room.roomName = roomResponse.data.roomName;
            newActivity.room.roomCode = roomResponse.data.roomCode;

            newActivity.activitySeq = 0;
            newActivity.excluded = false;
            newActivity.replacedScheduleId = null;
            newActivity.status = "";
            newActivity.additional = true;

            const activitySchedules = this.state.activitySchedules;
            activitySchedules.push(newActivity);
            activitySchedules.sort(function (a, b) {
              if (a.activityDate > b.activityDate) {
                return 1;
              } else if (a.activityDate < b.activityDate) {
                return -1;
              }
              return 0;
            });
            this.setState({activitySchedules});
            this.saveForm(this.state.data);
            swal("Poof! Jadwal aktivitas sudah berhasil ditambahkan. Mohon untuk menyimpan data kembali.", {icon: "success"});
          }
        }
      }
    });
  };

  handleCloseAddSchedule = async (e) => {
    const addActivity = {...this.state.addActivity};
    addActivity.addActivityTime = "";
    addActivity.addTeacherId = "";
    this.setState({addActivity});
  };

  handleCloseMoveSchedule = async (e) => {
    const moveActivity = {...this.state.moveActivity};
    moveActivity.moveActivityTime = "";
    moveActivity.moveTeacherId = "";
    moveActivity.moveRoomId = "";
    this.setState({moveActivity});
  };

  handleForfeitedSchedule = (item) => {
    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin akan menghanguskan aktivitas ini?",
      icon: "warning",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willForfeit) => {
      if (willForfeit) {

        swal("Catatan hangus harus di isi", {
          buttons: {cancel: "Batal"},
          content: "input",
        }).then((value) => {
          if (value !== null) {
            const currentSchedules = this.state.activitySchedules;
            currentSchedules.forEach(function (activitySchedule) {
              if (activitySchedule.id === item.id) {
                activitySchedule.excluded = true;
                activitySchedule.status = "4"; // Hangus
                activitySchedule.moreInfo = value;
                activitySchedule.activitySeq = null;

                const payload = {
                  "excluded": activitySchedule.excluded,
                  "status": activitySchedule.status,
                  "moreInfo": activitySchedule.moreInfo,
                  "activitySeq": activitySchedule.activitySeq
                };

                Axios.post(apiURL + "/activity-service/schedule-status/" + activitySchedule.id, payload);
              }
            });
            swal("Poof! Jadwal aktivitas sudah berhasil dihanguskan. Mohon untuk menyimpan data kembali.", {icon: "success"});

            const activitySchedules = this.state.activitySchedules;
            this.setState({activitySchedules});
          }
        });
      }
    });
  };

  undoForfeitedSchedule = (item) => {
    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin akan membatalkan penghangusan jadwal ini?",
      icon: "warning",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willCancel) => {
      if (willCancel) {
        const currentSchedules = this.state.activitySchedules;
        currentSchedules.forEach(function (activitySchedule) {
          if (activitySchedule.id === item.id) {
            activitySchedule.excluded = false;
            activitySchedule.status = ""; // New
            activitySchedule.moreInfo = "";

            const payload = {
              "excluded": activitySchedule.excluded,
              "status": activitySchedule.status,
              "moreInfo": activitySchedule.moreInfo
            };

            Axios.post(apiURL + "/activity-service/schedule-status/" + activitySchedule.id, payload);
          }
        });
        swal("Penghangusan jadwal sudah berhasil dibatalkan. Mohon untuk menyimpan data kembali.", {icon: "success"});

        const activitySchedules = this.state.activitySchedules;
        this.setState({activitySchedules});
      }
    });
  };

  handleDeleteSchedule = (item) => {
    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin untuk menghapus jadwal aktivitas ini?",
      icon: "warning",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        let activitySchedules = this.state.activitySchedules;
        await Axios.delete(apiURL + "/activitySchedules/" + item.id)
          .then(function (response) {
            if (response.status === 204) {
              activitySchedules = activitySchedules.filter(function (activitySchedule) {
                return activitySchedule.id !== item.id;
              });
              swal("Poof! Data sudah berhasil dihapus!", {icon: "success"});
            }
          });
        // TODO: Maybe failed
        this.setState({activitySchedules});
      }
    });
  };

  handleLeaveFormChange = async ({target: input}) => {
    const leave = {...this.state.leave};
    leave[input.name] = input.value;
    this.setState({leave});
  };

  handleProcessLeaveSchedule = async (e) => {
    const {leave} = this.state;
    const {activitySchedules} = this.state;

    const filteredStart = activitySchedules.filter(function (activitySchedule) {
      return activitySchedule.activityDate > leave.leaveStartDate;
    });

    const filteredEnd = activitySchedules.filter(function (activitySchedule) {
      return activitySchedule.activityDate > leave.leaveEndDate;
    });

    if (filteredStart.length > 0) {
      let nextStartDate;
      if (filteredEnd.length > 0) {
        nextStartDate = moment(filteredEnd[0].activityDate, "YYYY-MM-DD");
      } else {
        const datInNeed = this.state.data.courseDay;
        nextStartDate = moment(leave.leaveEndDate, "YYYY-MM-DD")
        if (nextStartDate.isoWeekday() <= datInNeed) {
          nextStartDate = nextStartDate.isoWeekday(datInNeed);
        } else {
          nextStartDate = nextStartDate.add(1, 'weeks').isoWeekday(datInNeed);
        }
      }

      filteredStart.forEach(function (activitySchedule) {
        activitySchedule.activityDate = nextStartDate.format("YYYY-MM-DD");
        nextStartDate = nextStartDate.add(7, 'days');
      });
    }

    this.setState({activitySchedules});
    const activityLeaves = this.state.activityLeaves;
    activityLeaves.push({
      "activityId": this.state.data.id,
      "startDate": this.state.leave.leaveStartDate,
      "endDate": this.state.leave.leaveEndDate
    });
    this.setState({activityLeaves});
  };

  printStudentActivity = () => {
    function printElement(elem) {
      let domClone = elem.cloneNode(true);
      let printSection = document.getElementById("printSection");

      if (!printSection) {
        printSection = document.createElement("div");
        printSection.id = "printSection";
        document.body.appendChild(printSection);
      } else {
        printSection.innerHTML = "";
      }
      printSection.appendChild(domClone);
    }

    printElement(document.getElementById("studentActivityContent"));
    window.print();
  };

  render() {
    const {loading, data, selectedStudent, studentsValueLabel, coursePackages, availableTeachers, activitySchedules, errors, payment, availableRooms, courseActivity, paymentSlip, moveActivity, teachers, addActivity, leave, activityLeaves, proofOfPayment, activity, student} = this.state;
    const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    const times = ["09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"];

    return (
      <React.Fragment>
        <Lines customLoading={loading} time={0}/>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Formulir Aktivitas</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-right my-2">
            <button type="submit"
                    className={"btn btn-" + ((data.id === 0 || data.outstanding === 0) ? "outline-dark" : "success") + " mb-3"}
                    disabled={data.id === 0 || data.outstanding === 0}
                    data-toggle="modal"
                    data-target="#activityPayment"
                    onClick={this.handleInitPayment}>
              <i className="fa fa-plus mr-2" aria-hidden="true"/>Pembayaran
            </button>
            <button type="submit" className="btn btn-success mb-3 ml-2" disabled={data.id === 0} data-toggle="modal"
                    data-target="#activityPaymentViewModal">
              <i className="fa fa-credit-card mr-2" aria-hidden="true"/>Lihat Pembayaran
            </button>
          </div>
        </div>

        <div className="row">
          &nbsp;
        </div>

        <form onSubmit={this.handleSubmit} ref="form">

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="studentId">Nama Siswa</label>
                <Select
                  id="studentId"
                  value={selectedStudent}
                  onChange={this.handleStudentSelectionChange}
                  options={studentsValueLabel}
                  className="student-single"
                  classNamePrefix="select"
                  isClearable={true} isDisabled={data.id > 0} placeholder={"-- Pilih Satu --"}/>
                {errors['studentId'] && <small className="text-danger">{errors['studentId']}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="registrationDate">Tanggal Registrasi</label>
                <input id="registrationDate"
                       name="registrationDate"
                       value={data.registrationDate}
                       onChange={this.handleChange}
                       type="date"
                       min={moment(new Date()).format("YYYY-MM-DD")}
                       disabled="disabled"
                       className="form-control"/>
                {errors['registrationDate'] && <small className="text-danger">{errors['registrationDate']}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="registrationNumber">No. Registrasi</label>
                <input id="registrationNumber"
                       name="registrationNumber"
                       value={"R-" + data.registrationNumber.toString().padStart(6, '0')}
                       onChange={this.handleChange}
                       type="text"
                       className="form-control" readOnly="readOnly"/>
                {errors['registrationNumber'] && <small className="text-danger">{errors['registrationNumber']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="roomId">Studio</label>
                <select id="roomId"
                        name="roomId"
                        value={data.roomId}
                        onChange={this.handleChange}
                        className="form-control"
                        disabled="disabled">
                  <option value="">-- Pilih Satu --</option>
                  {availableRooms.map((room, index) =>
                    <option key={index} value={room.id}>{room.roomName}</option>
                  )}
                </select>
                {errors['roomId'] && <small className="text-danger">{errors['roomId']}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="activityDate">Tanggal Mulai</label>
                <input id="activityDate"
                       name="activityDate"
                       value={data.activityDate}
                       onChange={this.handleChange}
                       type="date"
                       min={moment(new Date()).format("YYYY-MM-DD")}
                       disabled="disabled"
                       className="form-control"/>
                {errors['activityDate'] && <small className="text-danger">{errors['activityDate']}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="courseDay">Hari</label>
                <select id="courseDay"
                        name="courseDay"
                        value={data.courseDay}
                        onChange={this.handleChange}
                        className="form-control"
                        disabled="disabled">
                  <option value="">-- Pilih Hari ---</option>
                  {days.map((day, index) =>
                    <option key={index} value={index}>{day}</option>
                  )}
                </select>
                {errors['courseDay'] && <small className="text-danger">{errors['courseDay']}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="courseTime">Jam</label>
                <select id="courseTime"
                        name="courseTime"
                        value={data.courseTime}
                        onChange={this.handleChange}
                        className="form-control"
                        disabled="disabled">
                  <option value="">-- Pilih Jam ---</option>
                  {times.map((time, index) =>
                    <option key={index} value={index + 9}>{time}</option>
                  )}
                </select>
                {errors['courseTime'] && <small className="text-danger">{errors['courseTime']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="coursePackageId">Paket Kursus</label>
                <select id="coursePackageId"
                        name="coursePackageId"
                        value={data.coursePackageId}
                        onChange={this.handleChange}
                        disabled={data.id > 0}
                        className="form-control">
                  <option value="">-- Pilih Satu --</option>
                  {coursePackages.map((course, index) => (
                    <option key={index} value={course.id}>{course.packageName}</option>
                  ))}
                </select>
                {errors['coursePackageId'] && <small className="text-danger">{errors['coursePackageId']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="teacherId">Instruktur</label>
                <select id="teacherId"
                        name="teacherId"
                        value={data.teacherId}
                        onChange={this.handleChange}
                        disabled={data.id > 0}
                        className="form-control">
                  <option value="">-- Pilih Satu --</option>
                  {availableTeachers.map((teacher, index) => (
                    <option key={index} value={teacher.id}>{teacher.teacherName}</option>
                  ))}
                </select>
                {errors['teacherId'] && <small className="text-danger">{errors['teacherId']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="price">Total Biaya</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Rp</span>
                  </div>
                  <input id="price"
                         name="price"
                         value={data.totalAmount}
                         onChange={this.handleChange}
                         type="number"
                         className="form-control text-right" readOnly={true}/>
                  <div className="input-group-append">
                    <button type="button" className="btn btn-info"  style={{'zIndex': '0'}}
                            data-toggle="modal"
                            data-target="#activityPaymentDetail"
                            onClick={this.handleInitPaymentDetail} disabled={data.id === 0}>
                      <i className="fa fa-dollar mr-2"/>Detail
                    </button>
                  </div>
                </div>
                {errors['price'] && <small className="text-danger">{errors['price']}</small>}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="teacherFee">Biaya Instruktur</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Rp</span>
                  </div>
                  <input id="teacherFee"
                         name="teacherFee"
                         value={data.teacherFee}
                         onChange={this.handleChange}
                         type="number" pattern="#,###,###.00"
                         className="form-control text-right"/>
                  {errors['teacherFee'] && <small className="text-danger">{errors['teacherFee']}</small>}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="paidSession">Pertemuan Terbayar</label>
                <input id="paidSession"
                       name="paidSession"
                       value={data.paidSession}
                       onChange={this.handleChange}
                       type="number"
                       className="form-control text-right" readOnly={true}/>
                {errors['paidSession'] && <small className="text-danger">{errors['paidSession']}</small>}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="formOutstanding">Sisa Belum Terbayar</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Rp</span>
                  </div>
                  <input id="formOutstanding"
                         name="outstanding"
                         value={data.outstanding}
                         onChange={this.handleChange}
                         type="number"
                         className="form-control text-right" readOnly={true}/>
                </div>
                {errors['outstanding'] && <small className="text-danger">{errors['outstanding']}</small>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <select id="status"
                        name="status"
                        value={data.status}
                        onChange={this.handleChange}
                        className="form-control">
                  <option value="">-- Pilih Satu --</option>
                  <option value="Aktif">Aktif</option>
                  <option value="Cuti">Cuti</option>
                  <option value="Berakhir">Berakhir</option>
                </select>
                {errors['status'] && <small className="text-danger">{errors['status']}</small>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="formMoreInfo">Keterangan</label>
                <textarea id="formMoreInfo"
                          name="moreInfo"
                          value={data.moreInfo === null ? "" : data.moreInfo}
                          onChange={this.handleChange}
                          className="form-control" rows="3"/>
                {errors['moreInfo'] && <small className="text-danger">{errors['moreInfo']}</small>}
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12 text-right">
              <button id="printActivitiesButton" onClick={this.printStudentActivity} type="button"
                      className="btn btn-primary mr-2 mt-2" disabled={activitySchedules.length === 0}>
                <i className="fa fa-print mr-2" aria-hidden="true"/> Cetak Aktivitas
              </button>

              <button id="generateButton" onClick={this.handleGenerateSchedule} type="button"
                      className="btn btn-info mr-2 mt-2" disabled={activitySchedules.length > 0}>
                <i className="fa fa-gears mr-2" aria-hidden="true"/> Buat Jadwal
              </button>
              <button id="addMoreSchedule"
                      onClick={this.initAddSchedule}
                      type="button"
                      className="btn btn-success mr-2 mt-2"
                      data-toggle="modal" data-target="#addScheduleModal"
                      disabled={data.id === 0 || activitySchedules.length === 0}>
                <i className="fa fa-plus-circle mr-2" aria-hidden="true"/> Tambah
              </button>
              <button id="addLeaveSchedule"
                      type="button"
                      className="btn btn-warning mr-2 mt-2"
                      data-toggle="modal" data-target="#addLeaveModal"
                      disabled={data.id === 0 || activitySchedules.length === 0}>
                <i className="fa fa-plus-circle mr-2" aria-hidden="true"/> Cuti
              </button>
              <button id="clearSchedule"
                      onClick={this.handleClearSchedule}
                      type="button"
                      className="btn btn-danger mr-2 mt-2"
                      disabled={data.id === 0 || activitySchedules.length === 0 || this.scheduleCannotBeClearer()}>
                <i className="fa fa-eraser mr-2" aria-hidden="true"/> Bersihkan
              </button>
            </div>
          </div>

          <div className="my-3">
            <table className={(activitySchedules != null && activitySchedules.length > 0) ? "table table-sm table-hover table-bordered table-responsive" : "table table-sm table-hover table-bordered"}>
              <thead className="thead-light">
              <tr>
                <th className="fit">#</th>
                <th className="text-center">Aksi</th>
                <th className="fit">Tanggal</th>
                <th className="fit">Jam</th>
                <th className="fit">Status</th>
                <th className="fit">Instruktur</th>
                <th className="fit">Studio</th>
                <th className="text-center text-primary" colSpan="2">Mulai</th>
                <th className="text-center text-danger" colSpan="2">Selesai</th>
              </tr>
              </thead>
              <tbody>
              {activitySchedules.map((item, index) => (
                <tr key={index}
                    className={item.status === "4" ? "table-danger" : item.status === "3" ? "table-warning" : item.status === "2" ? "table-primary" : item.status === "1" ? "table-success" : (item.id === null || item.activitySeq > data.paidSession) ? "table-dark" : ""}>
                  <td className="text-right fit">
                    {item.additional === true ?
                      <i className="fa fa-flag-checkered mr-2 font-italic" aria-hidden="true" data-toggle="tooltip"
                         title="Jadwal Tambahan"/> : ""}
                    {index + 1}
                  </td>
                  <td className="fit text-left">
                    <button type="button" className="btn btn-info btn-sm mr-1" data-toggle="modal"
                            data-target="#courseActivityModal" onClick={() => this.handleCourseActivityEdit(item)}
                            disabled={item.id === null || item.exclude === true || item.status === null || item.status === "" || item.activitySeq > data.paidSession}>
                      <i className="fa fa-book" aria-hidden="true" data-toggle="tooltip" title="Info"/>
                    </button>
                    <button type="button" onClick={() => this.handleStartStopSchedule(item)}
                            className={item.status === null || item.status === "" ? "btn btn-success btn-sm mr-1" : "btn btn-danger btn-sm mr-1"}
                            disabled={item.id === null || item.status > 2 || (item.paid === true && item.status >= 2) || item.activitySeq > data.paidSession}>
                      <i className={item.id === null || item.status === null || item.status === "" ? "fa fa-play" : item.status === "1" ? "fa fa-stop" : item.status === "2" ? "fa fa-ban" : "fa fa-stop"}
                        aria-hidden="true" data-toggle="tooltip" title={item.status === "" ? "Mulai" : item.status === "1" ? "Selesai" : item.status === "2" ? "Batal" : ""}/>
                    </button>
                    <div className="btn-group btn-group-sm mr-1" role="group">
                      <button type="button" onClick={() => this.initMoveSchedule(item)}
                              className="btn btn-warning btn-sm"
                              disabled={item.id === null || item.status >= 1 || item.activitySeq > data.paidSession} data-toggle="modal"
                              data-target="#moveScheduleModal">
                        <i className="fa fa-send" aria-hidden="true" data-toggle="tooltip" title="Pindah"/>
                      </button>
                      <button type="button" onClick={() => this.undoMoveSchedule(item)}
                              className="btn btn-warning btn-sm"
                              disabled={item.id === null || item.status !== "3" || item.activitySeq > data.paidSession}>
                        <i className="fa fa-undo" data-toggle="tooltip" title="Batal Pindah"/>
                      </button>
                    </div>
                    <div className="btn-group btn-group-sm mr-1" role="group">
                      <button type="button" onClick={() => this.handleForfeitedSchedule(item)}
                              className="btn btn-danger btn-sm"
                              disabled={item.id === null || item.status >= 1 || item.activitySeq > data.paidSession}>
                        <i className="fa fa-fire" aria-hidden="true" data-toggle="tooltip" title="Hangus"/>
                      </button>
                      <button type="button" onClick={() => this.undoForfeitedSchedule(item)}
                              className="btn btn-danger btn-sm"
                              disabled={item.id === null || item.status !== "4" || item.activitySeq > data.paidSession}>
                        <i className="fa fa-undo" data-toggle="tooltip" title="Batal Hangus"/>
                      </button>
                    </div>
                    <button type="button" onClick={() => this.handleDeleteSchedule(item)}
                            className="btn btn-danger btn-sm mr-0"
                            disabled={item.additional === false || item.id === null || item.activitySeq > data.paidSession}>
                      <i className="fa fa-times" aria-hidden="true" data-toggle="tooltip" title="Hapus"/>
                    </button>
                  </td>
                  <td className="text-center fit">
                    {moment(item.activityDate).format("ddd, DD-MMM-YYYY")}
                  </td>
                  <td className="text-center fit">{item.startTime}</td>
                  <td className="text-primary text-center fit">
                    {item.status === "1" ? "BERLANGSUNG" : item.status === "2" ? "SELESAI" : item.status === "3" ? "PINDAH" : item.status === "4" ? "HANGUS" : ""}
                  </td>
                  <td>
                    <select id="activityTeacherId" style={{'width': '250px'}}
                            name="activityTeacherId"
                            onChange={(e) => this.handleChangeActivityTeacher(e, item)}
                            value={item.teacher.id}
                            className="form-control form-control-sm"
                            disabled={item.id === null || item.activitySeq > data.paidSession || item.status !== ""}>
                      <option value="">-- Pilih Satu --</option>
                      {teachers.map((teacher, index) => (
                        <option key={index} value={teacher.id}>{teacher.teacherName}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select id="activityRoomId" style={{'width': '250px'}}
                            name="activityRoomId"
                            onChange={(e) => this.handleChangeActivityRoom(e, item)}
                            value={item.room.id}
                            className="form-control form-control-sm"
                            disabled={item.id === null || item.activitySeq > data.paidSession || item.status !== ""}>
                      <option value="">-- Pilih Satu --</option>
                      {availableRooms.map((room, index) => (
                        <option key={index} value={room.id}>{room.roomName}</option>
                      ))}
                    </select>
                  </td>
                  <td
                    className="text-primary text-center fit">{item.actualStart !== null && item.actualStart !== "" ? moment(item.actualStart).format("HH:mm") : ""}</td>
                  <td className="text-primary fit">{item.startBy}</td>
                  <td
                    className="text-danger text-center fit">{item.actualFinish !== null && item.actualFinish !== "" ? moment(item.actualFinish).format("HH:mm") : ""}</td>
                  <td className="text-danger fit">{item.finishBy}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>

          <div className="row">
            <div className="col-md-6 my-4">
              <button type="submit" className="btn btn-primary mr-2" ref="btn" onClick={this.onClickSaveButton}>
                <i className="fa fa-floppy-o mr-2" aria-hidden="true"/>Simpan
              </button>

              <button type="reset" className="btn btn-outline-secondary"
                      onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left mr-2" aria-hidden="true"/>Tutup
              </button>
            </div>
          </div>

        </form>


        <div className="modal fade" id="activityPaymentDetail" tabIndex="-1" role="dialog"
             aria-labelledby="activityPaymentDetailLabel"
             aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="activityPaymentDetailModalLabel">Detail Biaya</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <form>
                      <div className="form-group row">
                        <label htmlFor="registrationFee" className="col-md-4 col-form-label">
                          Biaya Pendaftaran
                        </label>
                        <div className="col-md-8">
                          <input id="registrationFee"
                                 name="registrationFee"
                                 min="0"
                                 value={data.registrationFee}
                                 type="number"
                                 onChange={this.handlePaymentDetailFormChange}
                                 className="form-control text-right"
                                 disabled={this.state.data.totalPayment !== 0}/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="activityFee" className="col-md-4 col-form-label">Biaya
                          Kegiatan</label>
                        <div className="col-md-8">
                          <input id="activityFee"
                                 name="activityFee"
                                 type="number"
                                 value={data.price}
                                 min="0"
                                 onChange={this.handlePaymentDetailFormChange}
                                 className="form-control text-right"
                                 disabled="disabled"/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="othersFee" className="col-md-4 col-form-label">Biaya
                          Lain-Lain</label>
                        <div className="col-md-8">
                          <input id="othersFee"
                                 name="othersFee"
                                 type="number"
                                 value={data.othersFee}
                                 min="0"
                                 onChange={this.handlePaymentDetailFormChange}
                                 className="form-control text-right"
                                 disabled={this.state.data.totalPayment !== 0}/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="discountPercent"
                               className="col-md-4 col-form-label">Diskon</label>
                        <div className="col-md-3">
                          <div className="input-group">
                            <input id="discountPercent"
                                   name="discountPercent"
                                   type="number"
                                   min="0"
                                   max="100"
                                   value={data.discountPercent}
                                   onChange={this.handlePaymentDetailFormChange}
                                   className="form-control text-right"
                                   disabled={this.state.data.totalPayment !== 0}/>
                            <div className="input-group-append">
                              <span className="input-group-text">%</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <input id="discountAmount"
                                 name="discountAmount"
                                 type="number"
                                 value={data.discountAmount}
                                 min="0"
                                 onChange={this.handlePaymentDetailFormChange}
                                 className="form-control text-right"
                                 disabled="disabled"/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="totalAmount" className="col-md-4 col-form-label">Jumlah
                          Total</label>
                        <div className="col-md-8">
                          <input id="totalAmount"
                                 name="totalAmount"
                                 value={data.totalAmount}
                                 onChange={this.handlePaymentDetailFormChange}
                                 type="number"
                                 min="0"
                                 className="form-control text-right"
                                 disabled="disabled"/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="paymentLevel" className="col-md-4 col-form-label">Total Telah Dibayar</label>
                        <div className="col-md-8">
                          <input id="paymentAmount"
                                 name="paymentAmount"
                                 value={data.totalPayment}
                                 onChange={this.handlePaymentDetailFormChange}
                                 type="number"
                                 min="0"
                                 className="form-control text-right font-weight-bold"
                                 disabled="disabled"/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="outstanding" className="col-md-4 col-form-label">Sisa Belum Terbayar</label>
                        <div className="col-md-8">
                          <input id="outstanding"
                                 name="outstanding"
                                 type="number"
                                 value={data.outstanding}
                                 min="0"
                                 onChange={this.handlePaymentDetailFormChange}
                                 className="form-control text-right font-weight-bold"
                                 disabled="disabled"/>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-md-12">
                    <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">
                      <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                    </button>
                    <button onClick={this.handleSaveDetailPayment} type="button" className="btn btn-primary"
                            data-dismiss="modal" disabled={this.state.data.totalPayment !== 0}>
                      <i className="fa fa-save mr-2" aria-hidden="true"/>Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="courseActivityModal" tabIndex="-1" role="dialog"
             aria-labelledby="courseActivityModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" role="document">
            <form>

              <div className="modal-content modal-lg">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Catatan / Remarks</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="moreInfo">
                          {courseActivity.status === "3" || courseActivity.status === "4" ? "Keterangan" : "Materi Pelajaran"}
                        </label>
                        <textarea id="moreInfo"
                                  name="moreInfo"
                                  value={courseActivity.moreInfo}
                                  onChange={this.handleCourseActivityFormChange}
                                  className="form-control" rows="5"/>
                      </div>
                    </div>
                  </div>
                  {(courseActivity.status === "1" || courseActivity.status === "2") &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="instructorInfo">Komentar Instruktur</label>
                        <textarea id="instructorInfo"
                                  name="instructorInfo"
                                  value={courseActivity.instructorInfo}
                                  onChange={this.handleCourseActivityFormChange}
                                  className="form-control" rows="5"/>
                      </div>
                    </div>
                  </div>
                  }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">
                    <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                  </button>
                  <button onClick={this.handleAddCourseActivity} type="button" className="btn btn-primary"
                          data-dismiss="modal">
                    <i className="fa fa-save mr-2" aria-hidden="true"/>Simpan
                  </button>
                </div>
              </div>

            </form>
          </div>
        </div>

        <div className="modal fade" id="activityPaymentViewModal" tabIndex="-1" role="dialog"
             aria-labelledby="activityPaymentViewModalLabel"
             aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Daftar Pembayaran</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Tutup">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <table className="table table-hover table-bordered">
                  <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Tanggal</th>
                    <th>No Pembayaran</th>
                    <th>Cara Bayar</th>
                    <th>Jumlah</th>
                    <th>Aksi</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.activityPayments.map((item, index) => (
                    <tr key={item.id}>
                      <td className="text-right fit">{item.paymentTerm}</td>
                      <td className="text-center fit">{moment(item.paymentDate).format("DD-MMM-YYYY")}</td>
                      <td className="text-center fit">{item.paymentNumber}</td>
                      <td>{item.paymentMethod}</td>
                      <td className="text-right">
                        <NumberFormat value={item.paymentAmount + item.othersFeeAmount}
                                      displayType={"text"}
                                      thousandSeparator={"."}
                                      decimalSeparator={","} prefix={"Rp"}
                                      decimalScale={"2"}
                                      fixedDecimalScale={true}/>
                      </td>
                      <td className="text-center fit">
                        <button onClick={() => this.handlePrintPreparation(item)} data-toggle="modal"
                                data-target="#paymentSlipModal" title="Cetak Pembayaran"
                                className="btn btn-success btn-sm mr-2">
                          <i className="fa fa-print" aria-hidden="true"/>
                        </button>
                        <button onClick={() => this.handleViewProofOfPayment(item)} data-toggle="modal"
                                data-target="#paymentProofModal"
                                className="btn btn-info btn-sm mr-2" title="Lihat Bukti">
                          <i className="fa fa-image" aria-hidden="true"/>
                        </button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="activityPayment" tabIndex="-1" role="dialog"
             aria-labelledby="activityPaymentLabel"
             aria-hidden="true">
          <div className="modal-dialog  modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Pembayaran</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">

                    <table className="table table-bordered table-striped">
                      <tbody>
                      <tr>
                        <td>Biaya Pendaftaran</td>
                        <td className="text-right">
                          <NumberFormat value={data.registrationFee}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","} prefix={"Rp"}
                                        decimalScale={"2"}
                                        fixedDecimalScale={true}/>
                        </td>
                      </tr>
                      <tr>
                        <td>Biaya Kegiatan</td>
                        <td className="text-right">
                          <NumberFormat value={data.price}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","} prefix={"Rp"}
                                        decimalScale={"2"}
                                        fixedDecimalScale={true}/>
                        </td>
                      </tr>
                      <tr>
                        <td>Biaya Lain-Lain</td>
                        <td className="text-right">
                          <NumberFormat value={data.othersFee}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","} prefix={"Rp"}
                                        decimalScale={"2"}
                                        fixedDecimalScale={true}/>
                        </td>
                      </tr>
                      <tr>
                        <td>Diskon</td>
                        <td className="text-right">
                          <NumberFormat value={data.discountPercent}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","} suffix={"%"}
                                        decimalScale={"2"}
                                        fixedDecimalScale={true}/>&nbsp;-&nbsp;

                          <NumberFormat value={data.discountAmount}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","} prefix={"Rp"}
                                        decimalScale={"2"}
                                        fixedDecimalScale={true}/>
                        </td>
                      </tr>
                      <tr>
                        <td>Jumlah Total</td>
                        <td className="text-right">
                          <NumberFormat value={data.totalAmount}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","} prefix={"Rp"}
                                        decimalScale={"2"}
                                        fixedDecimalScale={true}/>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Total Terbayar</td>
                        <td className="text-right font-weight-bold text-success">
                          <NumberFormat value={data.totalPayment}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","} prefix={"Rp"}
                                        decimalScale={"2"}
                                        fixedDecimalScale={true}/>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Sisa Belum Terbayar</td>
                        <td className="text-right font-weight-bold text-danger">
                          <NumberFormat value={data.outstanding}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","} prefix={"Rp"}
                                        decimalScale={"2"}
                                        fixedDecimalScale={true}/>
                        </td>
                      </tr>
                      </tbody>
                    </table>

                    <form>
                      <div className="form-group row">
                        <label htmlFor="paymentLevel" className="col-md-4 col-form-label">
                          Jenis Pembayaran Kegiatan
                        </label>
                        <div className="col-md-3">
                          <select id="paymentLevel"
                                  name="paymentLevel"
                                  value={payment.paymentLevel}
                                  onChange={this.handlePaymentFormChange}
                                  className="form-control">
                            <option value="1">Penuh</option>
                            <option value="2" disabled={(data.numberOfSessions - data.paidSession) % 2 !== 0}>1/2
                            </option>
                            <option value="3" disabled={(data.numberOfSessions - data.paidSession) % 3 !== 0}>1/3
                            </option>
                            <option value="4" disabled={(data.numberOfSessions - data.paidSession) % 4 !== 0}>1/4
                            </option>
                          </select>
                        </div>
                        <div className="col-md-5">
                          <input id="paymentAmount"
                                 name="paymentAmount"
                                 value={payment.paymentAmount}
                                 onChange={this.handlePaymentFormChange}
                                 type="number"
                                 min="0"
                                 className="form-control text-right font-weight-bold"
                                 disabled="disabled"/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="paymentLevel" className="col-md-4 col-form-label">
                          Biaya Lain-lain
                        </label>
                        <div className="col-md-8">
                          <input id="othersFeeAmount"
                                 name="othersFeeAmount"
                                 value={payment.othersFeeAmount}
                                 onChange={this.handlePaymentFormChange}
                                 type="number"
                                 min="0" max={payment.othersFeeAmount}
                                 className="form-control text-right"/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="discountPercent"
                               className="col-md-4 col-form-label">Diskon</label>
                        <div className="col-md-3">
                          <div className="input-group">
                            <input id="discountPercent"
                                   name="discountPercent"
                                   type="number"
                                   min="0"
                                   max="100"
                                   value={payment.discountPercent}
                                   onChange={this.handlePaymentFormChange}
                                   className="form-control text-right"
                                   disabled={this.state.data.outstanding === 0}/>
                            <div className="input-group-append">
                              <span className="input-group-text">%</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <input id="discountAmount"
                                 name="discountAmount"
                                 type="number"
                                 value={payment.discountAmount}
                                 min="0"
                                 onChange={this.handlePaymentFormChange}
                                 className="form-control text-right"
                                 disabled="disabled"/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="paymentLevel" className="col-md-4 col-form-label">
                          Total Harus Dibayar
                        </label>
                        <div className="col-md-8">
                          <input id="paymentAmount"
                                 name="paymentAmount"
                                 value={payment.totalPayment}
                                 onChange={this.handlePaymentFormChange}
                                 type="number"
                                 min="0"
                                 className="form-control text-right"
                                 disabled="disabled"/>
                        </div>
                      </div>

                      {/*<div className="form-group row">*/}
                      {/*  <label htmlFor="outstanding" className="col-md-4 col-form-label">Sisa*/}
                      {/*    Pembayaran</label>*/}
                      {/*  <div className="col-md-8">*/}
                      {/*    <input id="outstanding"*/}
                      {/*           name="outstanding"*/}
                      {/*           type="number"*/}
                      {/*           value={payment.outstanding}*/}
                      {/*           onChange={this.handlePaymentFormChange}*/}
                      {/*           min="0"*/}
                      {/*           className="form-control text-right font-weight-bold"*/}
                      {/*           disabled="disabled"/>*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      <div className="form-group row">
                        <label htmlFor="paymentTerm" className="col-md-4 col-form-label">Pembayaran
                          Ke</label>
                        <div className="col-md-8">
                          <input id="paymentTerm"
                                 name="paymentTerm"
                                 type="number"
                                 value={payment.paymentTerm}
                                 onChange={this.handlePaymentFormChange}
                                 className="form-control text-right font-weight-bold"
                                 disabled="disabled"/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="paymentDate" className="col-md-4 col-form-label">Tanggal
                          Bayar</label>
                        <div className="col-md-8">
                          <input id="paymentDate"
                                 name="paymentDate"
                                 value={payment.paymentDate}
                                 onChange={this.handlePaymentFormChange}
                                 type="date"
                                 className="form-control"/>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="paymentMethod" className="col-md-4 col-form-label">Cara
                          Pembayaran</label>
                        <div className="col-md-8">
                          <select id="paymentMethod"
                                  name="paymentMethod"
                                  value={payment.paymentMethod}
                                  onChange={this.handlePaymentFormChange}
                                  className="form-control">
                            <option value="CASH">Cash</option>
                            <option value="TRANSFER">Transfer</option>
                            <option value="DEBIT-BNI">Debit BNI</option>
                            <option value="DEBIT-BCA">Debit BCA</option>
                            <option value="DEBIT-MANDIRI">Debit Mandiri</option>
                            <option value="DEBIT-MAYBANK">Debit Maybank</option>
                            <option value="DEBIT">Debit Lain-Lain</option>
                            <option value="CC-BNI">CC BNI</option>
                            <option value="CC-BCA">CC BCA</option>
                            <option value="CC-MANDIRI">CC Mandiri</option>
                            <option value="CC-MAYBANK">CC Maybank</option>
                            <option value="CC">CC Lain-Lain</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="proofOfPayment" className="col-md-4 col-form-label">Bukti Pembayaran</label>
                        <div className="col-md-8">
                        <div className="custom-file">
                          <input id="proofOfPayment"
                                 name="proofOfPayment"
                                 value=""
                                 onChange={this.handlePaymentFormChange}
                                 type="file"
                                 className="custom-file-input"/>
                          <label className="custom-file-label" htmlFor="proofOfPayment">Upload Bukti</label>
                        </div>
                      </div>
                      </div>

                      <div className="form-group row">
                        <label htmlFor="paymentNote" className="col-md-4 col-form-label">Catatan</label>
                        <div className="col-md-8">
                          <div className="custom-file">
                            <textarea id="paymentNote"
                                      name="paymentNote"
                                      value={payment.paymentNote}
                                      onChange={this.handlePaymentFormChange}
                                      className="form-control" rows="3"/>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                </button>
                <button onClick={this.handleSavePayment} type="button" className="btn btn-primary"
                        data-dismiss="modal">
                  <i className="fa fa-save mr-2" aria-hidden="true"/>Simpan
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="printPaymentSlip">
          <div className="modal fade" id="paymentSlipModal" tabIndex="-1" role="dialog"
               aria-labelledby="paymentSlipModalLabel"
               aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
              <form>

                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Kwitansi Pembayaran</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div id="paymentSlipContent" className="modal-body">
                    <div className="row">
                      <div className="col-md-6">
                        <img src={"/logo-with-text.jpg"} height="120" alt="Balawan Music Training Centre"/>
                      </div>
                      <div className="col-md-6">
                        <table className="col-md-12">
                          <tbody>
                          <tr>
                            <td className="text-right">Jalan Suli No. 115 Denpasar 80223</td>
                          </tr>
                          <tr>
                            <td className="text-right">Phone: +62-361-225519</td>
                          </tr>
                          <tr>
                            <td className="text-right">Email: info@balawanbmtc.com</td>
                          </tr>
                          <tr>
                            <td className="text-right">Website: http://www.balawanbmtc.com</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row">
                      &nbsp;
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <table className="table-borderless" width="100%">
                          <tbody>
                          <tr>
                            <td>No Pembayaran</td>
                            <td>: {paymentSlip.paymentNumber}</td>
                            <td></td>
                            <td className="font-weight-bold">Info Pembayaran</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>Tanggal Pembayaran</td>
                            <td>: {moment(paymentSlip.paymentDate).format("DD-MMM-YYYY")} / Pembayaran
                              Ke-{paymentSlip.paymentTerm}</td>
                            <td></td>
                            <td>Total Tagihan</td>
                            <td>:</td>
                            <td className="text-right">
                              <NumberFormat value={data.totalAmount}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","} prefix={"Rp"}
                                            decimalScale={"2"}
                                            fixedDecimalScale={true}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Cara Pembayaran</td>
                            <td>: {paymentSlip.paymentMethod}</td>
                            <td></td>
                            <td>Sudah Terbayar</td>
                            <td>:</td>
                            <td className="text-right">
                              <NumberFormat value={paymentSlip.hasBeenPaid}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","} prefix={"Rp"}
                                            decimalScale={"2"}
                                            fixedDecimalScale={true}/>
                            </td>
                          </tr>
                          <tr>
                            <td>No. Induk</td>
                            <td>: {paymentSlip.activity.student.studentCode}</td>
                            <td></td>
                            <td>Kewajiban</td>
                            <td>:</td>
                            <td className="text-right">
                              <NumberFormat value={data.totalAmount - paymentSlip.hasBeenPaid}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","} prefix={"Rp"}
                                            decimalScale={"2"}
                                            fixedDecimalScale={true}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Nama Siswa</td>
                            <td>: {paymentSlip.activity.student.name}</td>
                            <td></td>
                            <td>Biaya Kegiatan</td>
                            <td>:</td>
                            <td className="text-right">
                              <NumberFormat value={paymentSlip.paymentAmount}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","} prefix={"Rp"}
                                            decimalScale={"2"}
                                            fixedDecimalScale={true}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Kelas</td>
                            <td>: {paymentSlip.activity.coursePackage.packageName}</td>
                            <td></td>
                            <td>Biaya Lain-Lain</td>
                            <td>:</td>
                            <td className="text-right">
                              <NumberFormat value={paymentSlip.othersFeeAmount}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","} prefix={"Rp"}
                                            decimalScale={"2"}
                                            fixedDecimalScale={true}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Nomor Regitrasi</td>
                            <td>: {paymentSlip.activity.registrationNumberText}</td>
                            <td></td>
                            <td>Diskon</td>
                            <td>:</td>
                            <td className="text-right">
                              {paymentSlip.discountPercent}% - <NumberFormat value={paymentSlip.discountAmount}
                                                                             displayType={"text"}
                                                                             thousandSeparator={"."}
                                                                             decimalSeparator={","} prefix={"Rp"}
                                                                             decimalScale={"2"}
                                                                             fixedDecimalScale={true}/>
                            </td>
                          </tr>
                          <tr>
                            <td>Instruktur</td>
                            <td>: {paymentSlip.activity.teacherName}</td>
                            <td></td>
                            <td>Total Harus Dibayar</td>
                            <td>:</td>
                            <td className="text-right font-weight-bold">
                              <NumberFormat value={paymentSlip.totalPayment}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","} prefix={"Rp"}
                                            decimalScale={"2"}
                                            fixedDecimalScale={true}/>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Total Pembayaran</td>
                            <td>:</td>
                            <td className="text-right font-weight-bold">
                              <NumberFormat value={paymentSlip.totalPayment}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","} prefix={"Rp"}
                                            decimalScale={"2"}
                                            fixedDecimalScale={true}/>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Sisa Kewajiban</td>
                            <td>:</td>
                            <td className="text-right font-weight-bold">
                              <NumberFormat value={paymentSlip.outstanding}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","} prefix={"Rp"}
                                            decimalScale={"2"}
                                            fixedDecimalScale={true}/>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Pembayaran</td>
                            <td>:</td>
                            <td className="text-right">
                              {paymentSlip.paymentLevel === "1" ? "Penuh" : "1/" + paymentSlip.paymentLevel}
                            </td>
                          </tr>
                          {(paymentSlip.paymentNote != null && paymentSlip.paymentNote !== "") ?
                          <tr>
                            <td colSpan={6}>&nbsp;</td>
                          </tr>:""}
                          {(paymentSlip.paymentNote != null && paymentSlip.paymentNote !== "") ?
                          <tr>
                            <td colSpan={6}>
                              <div className="font-weight-bolder">Catatan:</div>
                              <div>{paymentSlip.paymentNote}</div>
                            </td>
                          </tr>:""}
                          </tbody>
                        </table>
                        <table className="table-borderless" width="100%">
                          <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td width="50%">
                              <div className="font-weight-bolder">Keterangan :</div>
                              <div>
                                Mohon simpanlah Kwitansi Pembayaran anda,
                                komplain tanpa Kwitansi Pembayaran tidak
                                akan dilayani.
                              </div>
                              <div>
                                Terima Kasih
                              </div>
                            </td>
                            <td width="50%" className="text-center">
                              <div className="text-center">Kasir / Diterima Oleh</div>
                              <div>&nbsp;</div>
                              <div>&nbsp;</div>
                              <div>&nbsp;</div>
                              <div className="font-weight-bolder text-center">-</div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">
                      <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                    </button>
                    <button onClick={this.handlePrintSlip} type="button" className="btn btn-primary"
                            data-dismiss="modal">
                      <i className="fa fa-print mr-2" aria-hidden="true"/>Cetak Kwitansi
                    </button>
                  </div>
                </div>

              </form>
              `
            </div>
          </div>
        </div>

        <div id="printPaymentSlip">
          <div className="modal fade" id="paymentProofModal" tabIndex="-1" role="dialog"
               aria-labelledby="paymentProofModalLabel"
               aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Bukti Pembayaran</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Tutup">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <img src={proofOfPayment} className="img-thumbnail" alt={"Bukti Pembayaran"}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">
                    <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="moveScheduleModal" tabIndex="-1" role="dialog"
             aria-labelledby="moveScheduleModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Form Pindah Jadwal</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Tutup">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="card">
                    <div className="card-header bg-transparent font-weight-bold">Pindah Ke:</div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="moveActivityDate">Tanggal</label>
                            <input id="moveActivityDate"
                                   name="moveActivityDate"
                                   value={moveActivity.moveActivityDate}
                                   onChange={this.handleMoveChange}
                                   type="date"
                                   className="form-control"/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="moveActivityTime">Jam</label>
                            <select id="moveActivityTime"
                                    name="moveActivityTime"
                                    value={parseInt(moveActivity.moveActivityTime).toString()}
                                    onChange={this.handleMoveChange}
                                    className="form-control">
                              <option value="">-- Pilih Jam ---</option>
                              {times.map((time, index) =>
                                <option key={index} value={index + 9}>{time}</option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="moveTeacherId">Instruktur</label>
                            <select id="moveTeacherId"
                                    name="moveTeacherId"
                                    value={moveActivity.moveTeacherId}
                                    onChange={this.handleMoveChange}
                                    className="form-control">
                              <option value="">-- Pilih Satu --</option>
                              {teachers.map((teacher, index) => (
                                <option key={index} value={teacher.id}>{teacher.teacherName}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="moveRoomId">Studio</label>
                            <select id="moveRoomId"
                                    name="moveRoomId"
                                    value={moveActivity.moveRoomId}
                                    onChange={this.handleMoveChange}
                                    className="form-control">
                              <option value="">-- Pilih Satu --</option>
                              {availableRooms.map((room, index) => (
                                <option key={index} value={room.id}>{room.roomName}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-transparent text-primary">* Default pindah adalah jadwal
                      berikutnya.
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button onClick={this.handleCloseMoveSchedule} type="button" className="btn btn-secondary"
                        data-dismiss="modal">
                  <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                </button>
                <button onClick={this.handleProcessMoveSchedule} type="button" className="btn btn-primary"
                        data-dismiss="modal">
                  <i className="fa fa-gear mr-2" aria-hidden="true"/>Proses
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="addScheduleModal" tabIndex="-1" role="dialog"
             aria-labelledby="addScheduleModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addScheduleModalLabel">Form Tambah Jadwal</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Tutup">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="card">
                    <div className="card-header bg-transparent font-weight-bold">Jadwal Baru Pada:</div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="addActivityDate">Tanggal</label>
                            <input id="addActivityDate"
                                   name="addActivityDate"
                                   value={addActivity.addActivityDate}
                                   onChange={this.handleAddScheduleChange}
                                   type="date"
                                   className="form-control"/>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="addActivityTime">Jam</label>
                            <select id="addActivityTime"
                                    name="addActivityTime"
                                    value={parseInt(addActivity.addActivityTime).toString()}
                                    onChange={this.handleAddScheduleChange}
                                    className="form-control">
                              <option value="">-- Pilih Jam ---</option>
                              {times.map((time, index) =>
                                <option key={index} value={index + 9}>{time}</option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="addTeacherId">Instruktur</label>
                            <select id="addTeacherId"
                                    name="addTeacherId"
                                    value={addActivity.addTeacherId}
                                    onChange={this.handleAddScheduleChange}
                                    className="form-control">
                              <option value="">-- Pilih Satu --</option>
                              {teachers.map((teacher, index) => (
                                <option key={index} value={teacher.id}>{teacher.teacherName}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="addRoomId">Studio</label>
                            <select id="addRoomId"
                                    name="addRoomId"
                                    value={addActivity.addRoomId}
                                    onChange={this.handleAddScheduleChange}
                                    className="form-control">
                              <option value="">-- Pilih Satu --</option>
                              {availableRooms.map((room, index) => (
                                <option key={index} value={room.id}>{room.roomName}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-transparent text-primary">* Default jadwal baru adalah jadwal
                      berikutnya.
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button onClick={this.handleCloseAddSchedule} type="button" className="btn btn-secondary"
                        data-dismiss="modal">
                  <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                </button>
                <button onClick={this.handleProcessAddSchedule} type="button" className="btn btn-primary"
                        data-dismiss="modal">
                  <i className="fa fa-gear mr-2" aria-hidden="true"/>Proses
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="addLeaveModal" tabIndex="-1" role="dialog"
             aria-labelledby="addLeaveModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addScheduleModalLabel">Form Cuti</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Tutup">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <table className="table table-bordered table-striped">
                  <thead className="thead-light">
                  <tr>
                    <th className=" text-right fit">#</th>
                    <th>Mulai</th>
                    <th>Sampai</th>
                  </tr>
                  </thead>
                  <tbody>
                  {activityLeaves.map((item, index) => (
                    <tr key={index}>
                      <td className="text-right">{index + 1}</td>
                      <td>{moment(item.startDate).format("dddd, DD-MMM-YYYY")}</td>
                      <td>{moment(item.endDate).format("dddd, DD-MMM-YYYY")}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>

                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="leaveStartDate">Mulai</label>
                        <input id="leaveStartDate" name="leaveStartDate" type="date" className="form-control"
                               value={leave.leaveStartDate}
                               onChange={this.handleLeaveFormChange}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="leaveEndDate">Sampai</label>
                        <input id="leaveEndDate" name="leaveEndDate" type="date" className="form-control"
                               value={leave.leaveEndDate}
                               onChange={this.handleLeaveFormChange}/>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                </button>
                <button onClick={this.handleProcessLeaveSchedule} type="button" className="btn btn-primary"
                        data-dismiss="modal">
                  <i className="fa fa-gear mr-2" aria-hidden="true"/>Proses
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="printStudentActivity">
          <div className="modal fade" id="studentActivityModal" tabIndex="-1" role="dialog"
               aria-labelledby="studentActivityModalLabel"
               aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Aktivitas Siswa</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div id="studentActivityContent" className="modal-body">

                  <div className="row">
                    <div className="col-md-12">
                      <h6><u>CATATAN PENTING</u></h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-borderless table-sm">
                        <tbody>
                        <tr>
                          <td>Nama Siswa</td>
                          <td>:</td>
                          <td>{activity.studentName}</td>
                          <td></td>
                          <td></td>
                          <td className="text-right" rowSpan="3" colSpan="3">
                            <img src={"/logo-with-text.jpg"}
                                 height="120"
                                 alt="Balawan Music Training Centre"/>
                          </td>
                        </tr>
                        <tr>
                          <td>Program & Jadwal</td>
                          <td>:</td>
                          <td>{activity.packageName}, {moment(activity.activityDate).format("dddd")} {activity.courseTime + ":00"} WITA</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>No. Telp</td>
                          <td>:</td>
                          <td>{student.phone}</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Instruktur</td>
                          <td>:</td>
                          <td>{activity.teacherName}</td>
                          <td></td>
                          <td></td>
                          <td>No. Induk</td>
                          <td>:</td>
                          <td>{student.studentCode}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-bordered table-striped table-sm">
                        <thead>
                        <tr>
                          <th className="text-center fit">Minggu<br/>Ke</th>
                          <th className="text-center fit">Hari & Jam<br/>Tanggal</th>
                          <th className="text-center align-middle">Materi Pelajaran</th>
                          <th className="text-center align-middle">Komentar Instruktur</th>
                          <th className="text-center align-middle">Instruktur</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activitySchedules.filter(function(item) {return item.activitySeq !== 0 || item.additional === true}).map((item, index) => (
                          <tr key={index}>
                            <td className="text-right">{index + 1}</td>
                            <td className="fit">{moment(item.activityDate).format("dddd, DD-MMM-YYYY")} {item.startTime + ":00"}</td>
                            <td>{item.moreInfo}</td>
                            <td>{item.instructorInfo}</td>
                            <td className="fit">{item.teacher.teacherName}</td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">
                    <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                  </button>
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.printStudentActivity}>
                    <i className="fa fa-print mr-2" aria-hidden="true"/>Cetak Aktivitas
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ActivityFormDay;
