import React, {Component} from 'react';
import {apiURL} from "../../config";
import Axios from "axios";
import localeId from "moment/locale/id";
import moment from "moment";

const activityServiceEndpoint = apiURL + "/activity-service/registrationNumber";
const apiEndpoint = apiURL + "/activities";
const studentEndpoint = apiURL + "/students";

class StudentActivityReport extends Component {
  state = {
    data: {
      registrationNumber: ""
    },
    activity: {
    },
    activitySchedules: [],
    student: {}
  };

  handleChange = ({target: input}) => {
    const data = {...this.state.data};
    data[input.name] = input.value;
    this.setState({data});
  };

  prepareActivityDocument = async () => {
    if (this.state.data.registrationNumber !== "") {
      try {
        let {data} = await Axios.get(activityServiceEndpoint + "?regnum=" + this.state.data.registrationNumber);
        const response = await Axios.get(apiEndpoint + "/" + data);
        const activity = response.data;
        this.setState({activity});
        if (activity._embedded.activitySchedules) {
          this.setState({activitySchedules: activity._embedded.activitySchedules})
          const resStudent = await Axios.get(studentEndpoint + "/" + activity.studentId);
          const student = resStudent.data;
          this.setState({student});
        }
      } catch (e) {
        if (e.response && e.response.status === 404) {
          this.props.history.replace("/not-found");
        }
      }
    }
  };

  printStudentActivity = () => {
    function printElement(elem) {
      let domClone = elem.cloneNode(true);
      let printSection = document.getElementById("printSection");

      if (!printSection) {
        printSection = document.createElement("div");
        printSection.id = "printSection";
        document.body.appendChild(printSection);
      } else {
        printSection.innerHTML = "";
      }
      printSection.appendChild(domClone);
    }

    printElement(document.getElementById("studentActivityContent"));
    window.print();
  };

  render() {
    const {data, activity, student, activitySchedules} = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <h3><i className="fa fa-angle-double-right mr-2"/>Cetak Aktivitas Siswa</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="registrationNumber">No. Registrasi</label>
              <input id="registrationNumber"
                     name="registrationNumber"
                     value={data.registrationNumber}
                     onChange={this.handleChange}
                     type="text"
                     className="form-control" autoFocus/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 my-4">
            <button type="submit" className="btn btn-primary mr-2" data-toggle="modal"
                    data-target="#studentActivityModal" onClick={this.prepareActivityDocument}>
              <i className="fa fa-gears mr-2" aria-hidden="true"/>Tampilkan
            </button>
            <button type="reset" className="btn btn-outline-secondary"
                    onClick={() => this.props.history.goBack()}>
              <i className="fa fa-angle-double-left mr-2" aria-hidden="true"/>Kembali
            </button>
          </div>
        </div>

        <div id="printStudentActivity">
          <div className="modal fade" id="studentActivityModal" tabIndex="-1" role="dialog"
               aria-labelledby="studentActivityModalLabel"
               aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Aktivitas Siswa</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div id="studentActivityContent" className="modal-body">

                  <div className="row">
                    <div className="col-md-12">
                      <h6><u>CATATAN PENTING</u></h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-borderless table-sm">
                        <tr>
                          <td>Nama Siswa</td>
                          <td>:</td>
                          <td>{activity.studentName}</td>
                          <td></td>
                          <td></td>
                          <td className="text-right" rowSpan="3" colSpan="3">
                            <img src={"/logo-with-text.jpg"}
                                 height="120"
                                 alt="Balawan Music Training Centre"/>
                          </td>
                        </tr>
                        <tr>
                          <td>Program & Jadwal</td>
                          <td>:</td>
                          <td>{activity.packageName}, {moment(activity.activityDate).format("dddd")} {activity.courseTime + ":00"} WITA</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>No. Telp</td>
                          <td>:</td>
                          <td>{student.phone}</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Instruktur</td>
                          <td>:</td>
                          <td>{activity.teacherName}</td>
                          <td></td>
                          <td></td>
                          <td>No. Induk</td>
                          <td>:</td>
                          <td>{student.studentCode}</td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-bordered table-striped table-sm">
                        <thead>
                        <tr>
                          <th className="text-center fit">Minggu<br/>Ke</th>
                          <th className="text-center fit">Hari & Jam<br/>Tanggal</th>
                          <th className="text-center align-middle">Materi Pelajaran</th>
                          <th className="text-center align-middle">Komentar Instruktur</th>
                          <th className="text-center align-middle">Instruktur</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activitySchedules.filter(function(item) {return item.activitySeq !== 0}).map((item, index) => (
                          <tr key={index}>
                            <td className="text-right">{index + 1}</td>
                            <td className="fit">{moment(item.activityDate).format("dddd, DD-MMM-YYYY")} {item.startTime + ":00"}</td>
                            <td>{item.moreInfo}</td>
                            <td>{item.instructorInfo}</td>
                            <td className="fit">{item.teacher.teacherName}</td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">
                    <i className="fa fa-close mr-2" aria-hidden="true"/>Tutup
                  </button>
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.printStudentActivity}>
                    <i className="fa fa-print mr-2" aria-hidden="true"/>Cetak Aktivitas
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StudentActivityReport;
