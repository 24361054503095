import React, {Component} from 'react';
import {apiURL} from "../../config";
import Axios from "axios";
import localeId from "moment/locale/id";
import moment from "moment";
import swal from "@sweetalert/with-react/src/sweetalert";
import Calendar from "react-calendar";

const apiEndpoint = apiURL + "/room-occupancy-service/occupancies";

class StudioCalendar extends Component {
  state = {
    data: {
      activityDate: moment(new Date()).format("YYYY-MM-DD")
    },
    occupancies: [],
    holiday: {
      holidayName: null
    }
  };

  async componentDidMount() {
    try {
      const response = await Axios.get(apiURL + "/holiday-service/check?date=" + this.state.data.activityDate);
      this.setState({holiday: response.data});
    } catch (e) {
    }

    await this.populateData();
  }

  async populateData() {
    try {
      const {activityDate} = this.state.data;

      const url = `${apiEndpoint}?activityDate=${activityDate}`;
      const response = await Axios.get(url);
      const occupancies = response.data;
      this.setState({occupancies});
    } catch (e) {
    }
  }

  handleChange = ({target: input}) => {
    const data = {...this.state.data};
    data[input.name] = input.value;
    this.setState({data});
  };

  handleClick = () => {
    this.populateData();
  };

  handleView = async (id) => {
    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin untuk melihat aktivitas ini?",
      icon: "info",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willOpen) => {
      if (willOpen) {
        this.props.history.push("/activities/" + id);
      }
    });
  };

  handleNew = async (roomName, time) => {
    if (this.state.holiday.id !== undefined) {
      swal({
        title: "Informasi",
        text: "Tidak bisa mendaftarkan aktifitas di hari libur.",
        icon: "info"
      });
      return;
    }

    swal({
      title: "Konfirmasi",
      text: "Apakah anda yakin untuk mendaftarkan aktivitas baru?",
      icon: "info",
      buttons: ["Batal", "YA"],
      dangerMode: true
    }).then(async (willOpen) => {
      if (willOpen) {
        const date = moment(this.state.data.activityDate);
        const day = date.day();
        this.props.history.push("/activities/new", {
          "roomName": roomName,
          "date": this.state.data.activityDate,
          "day": day,
          "time": time
        });
      }
    });
  };

  onChange = async (value) => {
    const data = {...this.state.data};
    data.activityDate = moment(value).format("YYYY-MM-DD");
    this.setState({data}, () => {
      this.populateData();
    });

    try {
      const response = await Axios.get(apiURL + "/holiday-service/check?date=" + data.activityDate);
      this.setState({holiday: response.data});
    } catch (e) {
    }
  };

  render() {
    const {data} = this.state;
    const isHoliday = this.state.holiday.id !== undefined;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 my-4">
            <Calendar onChange={this.onChange} locale="id-ID" defaultValue={new Date()} value={new Date()}/>
          </div>
        </div>

        <div className="row">
          &nbsp;
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              {this.state.holiday.id != null && <h4 className="text-danger"><strong>{this.state.holiday.holidayName}</strong></h4>}
              <h5><strong>{moment(this.state.data.activityDate).format("dddd, DD MMMM YYYY")}</strong></h5>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-hover table-bordered table-sm">
              <thead className="thead-light">
              <tr>
                <th></th>
                <th colSpan="11" className="text-center">JAM</th>
              </tr>
              <tr>
                <th className="text-center">STUDIO</th>
                <th rowSpan="2" className="align-middle text-center">09:00</th>
                <th rowSpan="2" className="align-middle text-center">10:00</th>
                <th rowSpan="2" className="align-middle text-center">11:00</th>
                <th rowSpan="2" className="align-middle text-center">12:00</th>
                <th rowSpan="2" className="align-middle text-center">13:00</th>
                <th rowSpan="2" className="align-middle text-center">14:00</th>
                <th rowSpan="2" className="align-middle text-center">15:00</th>
                <th rowSpan="2" className="align-middle text-center">16:00</th>
                <th rowSpan="2" className="align-middle text-center">17:00</th>
                <th rowSpan="2" className="align-middle text-center">18:00</th>
                <th rowSpan="2" className="align-middle text-center">19:00</th>
              </tr>
              </thead>
              <tbody>
              {this.state.occupancies.map((item) => (
                <tr key={item.roomName}>
                  <td className="align-middle">{item.roomName}</td>
                  <td>{item.student9 !== null ? <button onClick={() => this.handleView(item.activity9)} className={item.activitySeq9 === item.paidSession9 - 1 ? "btn btn-warning btn-block" : item.paidSession9 <= item.activitySeq9 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student9} <br/><i className="fa fa-star m-1"/><br/> {item.teacher9}</button> : <button onClick={() => this.handleNew(item.roomName, 9)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                  <td>{item.student10 !== null ? <button onClick={() => this.handleView(item.activity10)} className={item.activitySeq10 === item.paidSession10 - 1 ? "btn btn-warning btn-block" : item.paidSession10 <= item.activitySeq10 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student10} <br/><i className="fa fa-star m-1"/><br/> {item.teacher10}</button> : <button onClick={() => this.handleNew(item.roomName, 10)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                  <td>{item.student11 !== null ? <button onClick={() => this.handleView(item.activity11)} className={item.activitySeq11 === item.paidSession11 - 1 ? "btn btn-warning btn-block" : item.paidSession11 <= item.activitySeq11 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student11} <br/><i className="fa fa-star m-1"/><br/> {item.teacher11}</button> : <button onClick={() => this.handleNew(item.roomName, 11)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                  <td>{item.student12 !== null ? <button onClick={() => this.handleView(item.activity12)} className={item.activitySeq12 === item.paidSession12 - 1 ? "btn btn-warning btn-block" : item.paidSession12 <= item.activitySeq12 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student12} <br/><i className="fa fa-star m-1"/><br/> {item.teacher12}</button> : <button onClick={() => this.handleNew(item.roomName, 12)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                  <td>{item.student13 !== null ? <button onClick={() => this.handleView(item.activity13)} className={item.activitySeq13 === item.paidSession13 - 1 ? "btn btn-warning btn-block" : item.paidSession13 <= item.activitySeq13 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student13} <br/><i className="fa fa-star m-1"/><br/> {item.teacher13}</button> : <button onClick={() => this.handleNew(item.roomName, 13)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                  <td>{item.student14 !== null ? <button onClick={() => this.handleView(item.activity14)} className={item.activitySeq14 === item.paidSession14 - 1 ? "btn btn-warning btn-block" : item.paidSession14 <= item.activitySeq14 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student14} <br/><i className="fa fa-star m-1"/><br/> {item.teacher14}</button> : <button onClick={() => this.handleNew(item.roomName, 14)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                  <td>{item.student15 !== null ? <button onClick={() => this.handleView(item.activity15)} className={item.activitySeq15 === item.paidSession15 - 1 ? "btn btn-warning btn-block" : item.paidSession15 <= item.activitySeq15 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student15} <br/><i className="fa fa-star m-1"/><br/> {item.teacher15}</button> : <button onClick={() => this.handleNew(item.roomName, 15)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                  <td>{item.student16 !== null ? <button onClick={() => this.handleView(item.activity16)} className={item.activitySeq16 === item.paidSession16 - 1 ? "btn btn-warning btn-block" : item.paidSession16 <= item.activitySeq16 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student16} <br/><i className="fa fa-star m-1"/><br/> {item.teacher16}</button> : <button onClick={() => this.handleNew(item.roomName, 16)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                  <td>{item.student17 !== null ? <button onClick={() => this.handleView(item.activity17)} className={item.activitySeq17 === item.paidSession17 - 1 ? "btn btn-warning btn-block" : item.paidSession17 <= item.activitySeq17 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student17} <br/><i className="fa fa-star m-1"/><br/> {item.teacher17}</button> : <button onClick={() => this.handleNew(item.roomName, 17)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                  <td>{item.student18 !== null ? <button onClick={() => this.handleView(item.activity18)} className={item.activitySeq18 === item.paidSession18 - 1 ? "btn btn-warning btn-block" : item.paidSession18 <= item.activitySeq18 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student18} <br/><i className="fa fa-star m-1"/><br/> {item.teacher18}</button> : <button onClick={() => this.handleNew(item.roomName, 18)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                  <td>{item.student19 !== null ? <button onClick={() => this.handleView(item.activity19)} className={item.activitySeq19 === item.paidSession19 - 1 ? "btn btn-warning btn-block" : item.paidSession19 <= item.activitySeq19 ? "btn btn-danger btn-block" : "btn btn-info btn-block"}>{item.student19} <br/><i className="fa fa-star m-1"/><br/> {item.teacher19}</button> : <button onClick={() => this.handleNew(item.roomName, 19)} className={isHoliday ? "btn btn-outline-danger btn-block" : "btn btn-outline-info btn-block"}><i className="fa fa-music m-1"/></button>}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <button type="button" className="btn btn-info mr-2">Normal</button>
            <button type="button" className="btn btn-warning mr-2">Pembayaran Tinggal Untuk 1x Pertemuan</button>
            <button type="button" className="btn btn-danger mr-2">Pembayaran Diperlukan</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StudioCalendar;
